import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { motion } from 'framer-motion';
import BackLink from "./BackLink";
import toast from "react-hot-toast";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";

const UploadAlbum = () => {
    const [albumData, setAlbumData] = useState({
        title: '',
        artist: '',
        album_art: null,
        tracks: []
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { width } = useWindowSize();

    const handleTrackUpload = (e, index) => {
        const file = e.target.files[0];
        if (!file) return;

        setAlbumData((prev) => {
            const newTracks = [...prev.tracks];
            if (!newTracks[index]) {
                newTracks[index] = { title: '', file: null };
            }
            newTracks[index].file = file;
            return { ...prev, tracks: newTracks };
        });
    };

    const handleTrackTitleChange = (e, index) => {
        const title = e.target.value;

        setAlbumData((prev) => {
            const newTracks = [...prev.tracks];
            if (!newTracks[index]) {
                newTracks[index] = { title: '', file: null };
            }
            newTracks[index].title = title;
            return { ...prev, tracks: newTracks };
        });
    };

    const addTrackField = () => {
        setAlbumData((prev) => ({
            ...prev,
            tracks: [...prev.tracks, { title: '', file: null }]
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('title', albumData.title);
        formData.append('artist', albumData.artist);
        formData.append('album_art', albumData.album_art);

        albumData.tracks.forEach((track, index) => {
            if (track.title && track.file) {
                formData.append(`tracks[${index}][title]`, track.title);
                formData.append(`tracks[${index}][file]`, track.file);
            }
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/albums`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to upload album');
            }

            toast.success('Альбом успешно загружен!');
            setTimeout(() => navigate('/music'), 2000);
        } catch (error) {
            console.error('Ошибка загрузки:', error);
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="container mx-auto p-4 pb-36"
        >
            {width < 768 && <BottomNav />}
            <BackLink />
            <h1 className="text-3xl font-extrabold text-neutral-800 dark:text-white mb-6">Загрузить новый альбом</h1>

            <form onSubmit={handleSubmit} className="max-w-3xl mx-auto space-y-6">
                {/* Поля альбома */}
                <div className="space-y-4">
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium text-neutral-700 dark:text-neutral-300">Обложка альбома</label>
                        <input
                            type="file"
                            onChange={(e) => setAlbumData({ ...albumData, album_art: e.target.files[0] })}
                            accept="image/*"
                            className="block w-full text-sm text-gray-700 dark:text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:bg-purple-100 file:text-purple-700 hover:file:bg-purple-200 dark:file:bg-neutral-700 dark:file:text-white transition duration-300 mb-4"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium text-neutral-700 dark:text-neutral-300">Название альбома</label>
                        <input
                            type="text"
                            placeholder="Введите название альбома"
                            value={albumData.title}
                            onChange={(e) => setAlbumData({ ...albumData, title: e.target.value })}
                            className="block w-full p-3 mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium text-neutral-700 dark:text-neutral-300">Исполнитель</label>
                        <input
                            type="text"
                            placeholder="Введите имя исполнителя"
                            value={albumData.artist}
                            onChange={(e) => setAlbumData({ ...albumData, artist: e.target.value })}
                            className="block w-full p-3 mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white"
                            required
                        />
                    </div>
                </div>

                {/* Секция треков */}
                <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-neutral-800 dark:text-white">Треки альбома</h3>

                    {albumData.tracks.map((track, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: index * 0.1 }}
                            className="flex flex-col gap-2"
                        >
                            <span className="text-gray-500 dark:text-neutral-400">{index + 1}.</span>
                            <input
                                type="text"
                                placeholder="Название трека"
                                value={track.title}
                                onChange={(e) => handleTrackTitleChange(e, index)}
                                className="block w-full p-3 mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white"
                                required
                            />
                            <input
                                type="file"
                                accept="audio/*"
                                onChange={(e) => handleTrackUpload(e, index)}
                                className="block w-full text-sm text-gray-700 dark:text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:bg-purple-100 file:text-purple-700 hover:file:bg-purple-200 dark:file:bg-neutral-700 dark:file:text-white transition duration-300 mb-4"
                                required
                            />
                        </motion.div>
                    ))}

                    <button
                        type="button"
                        onClick={addTrackField}
                        className="w-full bg-purple-500/10 text-purple-500 px-4 py-2 rounded-lg hover:bg-purple-500/20 transition-colors duration-200"
                    >
                        + Добавить трек
                    </button>
                </div>

                <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-purple-500 text-white px-6 py-3 rounded-lg flex justify-center items-center gap-2 hover:bg-purple-600 transition-colors duration-200"
                >
                    {loading ? (
                        <ClipLoader size={20} color="#fff" />
                    ) : (
                        'Загрузить альбом'
                    )}
                </button>
            </form>
        </motion.div>
    );
};

export default UploadAlbum;