import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminLayout from "./AdminLayout";
import { motion, AnimatePresence } from "framer-motion";

const AdminPanel = () => {
    const [users, setUsers] = useState([]);
    const [banData, setBanData] = useState({
        duration: '7',
        reason: '',
        comment: ''
    });
    const [showBanModal, setShowBanModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (!token) return;
        setLoading(true);
        fetchUsers();
    }, [token]);


    const fetchUsers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setUsers(response.data);
            setLoading(false);
        }).catch(error => {
            console.error("Error fetching users:", error);
            setLoading(false);
        });
    };

    const handleBan = (userId) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/admin/users/${userId}/ban`,
            banData,
            { headers: { Authorization: `Bearer ${token}` } }
        ).then(fetchUsers)
            .catch(console.error)
            .finally(() => setShowBanModal(false));
    };

    const handleUnban = (userId) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/admin/users/${userId}/unban`,
            {},
            { headers: { Authorization: `Bearer ${token}` } }
        ).then(fetchUsers)
            .catch(console.error);
    };

    const openBanModal = (user) => {
        setSelectedUser(user);
        setShowBanModal(true);
    };

    const modalVariants = {
        hidden: {
            opacity: 0,
            scale: 0.8,
            transition: { duration: 0.2 }
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 20
            }
        }
    };

    const backdropVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen bg-neutral-100 dark:bg-neutral-900">
                <div className="text-purple-500 dark:text-purple-400 text-xl font-semibold">
                    <i className="fas fa-spinner fa-spin mr-2" />
                    Загрузка данных...
                </div>
            </div>
        );
    }

    if (!users.length || users[0]?.role !== "admin") return (
        <div className="flex items-center justify-center h-screen bg-neutral-100 dark:bg-neutral-900">
            <p className="text-red-500 dark:text-red-400 text-xl font-semibold">
                <i className="fas fa-ban h-8 w-8 inline-block mr-2" />
                Доступ запрещён
            </p>
        </div>
    );

    return (
        <AdminLayout>
            {/* Боковая панель */}

            {/* Основной контент */}
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.3}}
            >
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                        <i className="fas fa-users text-purple-600 dark:text-purple-400 text-2xl mr-3"/>
                        <h2 className="text-3xl font-bold text-neutral-800 dark:text-neutral-200">Управление
                            пользователями</h2>
                    </div>
                    <button
                        className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded-lg flex items-center"
                        onClick={() => fetchUsers()}
                    >
                        <i className="fas fa-sync-alt mr-2"/>
                        Обновить
                    </button>
                </div>

                {/* Модальное окно бана */}
                <AnimatePresence>
                    {showBanModal && (
                        <motion.div
                            className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-80 flex items-center justify-center z-50 backdrop-blur-sm"
                            variants={backdropVariants}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                        >
                            <motion.div
                                className="bg-white dark:bg-neutral-800 p-6 rounded-xl w-full max-w-md shadow-2xl"
                                variants={modalVariants}
                            >
                                <h3 className="text-xl font-bold text-neutral-800 dark:text-neutral-200 mb-6">
                                    Бан пользователя <span className="text-purple-600">{selectedUser?.name}</span>
                                </h3>

                                <div className="space-y-4">
                                    <select
                                        value={banData.duration}
                                        onChange={(e) => setBanData({...banData, duration: e.target.value})}
                                        className="w-full p-3 border rounded-lg bg-white dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-200 focus:ring-2 focus:ring-purple-500"
                                    >
                                        <option value="1">1 день</option>
                                        <option value="3">3 дня</option>
                                        <option value="7">7 дней</option>
                                        <option value="30">30 дней</option>
                                        <option value="permanent">Перманентно</option>
                                    </select>

                                    <input
                                        type="text"
                                        placeholder="Причина бана"
                                        value={banData.reason}
                                        onChange={(e) => setBanData({...banData, reason: e.target.value})}
                                        className="w-full p-3 border rounded-lg bg-white dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-200 focus:ring-2 focus:ring-purple-500"
                                    />

                                    <textarea
                                        placeholder="Комментарий администратора"
                                        value={banData.comment}
                                        onChange={(e) => setBanData({...banData, comment: e.target.value})}
                                        className="w-full p-3 border rounded-lg bg-white dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-200 h-32 focus:ring-2 focus:ring-purple-500"
                                    />
                                </div>

                                <div className="flex justify-end gap-3 mt-6">
                                    <motion.button
                                        whileHover={{scale: 1.05}}
                                        whileTap={{scale: 0.95}}
                                        onClick={() => setShowBanModal(false)}
                                        className="px-5 py-2.5 bg-neutral-100 dark:bg-neutral-700 text-neutral-800 dark:text-neutral-200 rounded-lg hover:bg-neutral-200 dark:hover:bg-neutral-600 transition-colors"
                                    >
                                        Отмена
                                    </motion.button>
                                    <motion.button
                                        whileHover={{scale: 1.05}}
                                        whileTap={{scale: 0.95}}
                                        onClick={() => handleBan(selectedUser.id)}
                                        className="px-5 py-2.5 bg-red-500 text-white rounded-lg hover:bg-red-600 flex items-center gap-2 transition-colors"
                                    >
                                        <i className="fas fa-ban h-5 w-5"/>
                                        Забанить
                                    </motion.button>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Таблица пользователей */
                }
                <div className="overflow-x-auto rounded-xl border dark:border-neutral-700 shadow-sm">
                    <table className="w-full">
                        <thead className="bg-neutral-50 dark:bg-neutral-700">
                        <tr>
                            {["Имя", "Статус", "Причина", "Срок", "Комментарий", "Действия"].map((header) => (
                                <th
                                    key={header}
                                    className="px-4 py-3 text-left text-sm font-semibold text-neutral-700 dark:text-neutral-300 uppercase tracking-wider"
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                        {users.map((user) => (
                            <motion.tr
                                key={user.id}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.3 }}
                                className="hover:bg-neutral-50 dark:hover:bg-neutral-700/30 transition-colors"
                            >
                                <td className="px-4 py-3 text-neutral-800 dark:text-neutral-200 font-medium">
                                    {user.name}
                                </td>
                                <td className={`px-4 py-3 font-semibold ${
                                    user.is_banned ? "text-red-500" : "text-green-500"
                                }`}>
                                    {user.is_banned ? "Забанен" : "Активен"}
                                </td>
                                <td className="px-4 py-3 text-neutral-700 dark:text-neutral-300">
                                    {user.ban_reason || '-'}
                                </td>
                                <td className="px-4 py-3 text-neutral-700 dark:text-neutral-300">
                                    {user.banned_until
                                        ? new Date(user.banned_until).toLocaleDateString()
                                        : user.is_banned ? 'Перманентно' : '-'}
                                </td>
                                <td className="px-4 py-3 text-neutral-700 dark:text-neutral-300 max-w-xs truncate">
                                    {user.ban_admin_comment || '-'}
                                </td>
                                <td className="px-4 py-3">
                                    {user.is_banned ? (
                                        <motion.button
                                            whileHover={{scale: 1.05}}
                                            whileTap={{scale: 0.95}}
                                            onClick={() => handleUnban(user.id)}
                                            className="flex items-center gap-2 px-4 py-2 bg-green-500/10 text-green-600 dark:text-green-400 rounded-lg hover:bg-green-500/20 transition-colors"
                                        >
                                            <i className="fas fa-unlock h-5 w-5"/>
                                            <span>Разбанить</span>
                                        </motion.button>
                                    ) : (
                                        <motion.button
                                            whileHover={{scale: 1.05}}
                                            whileTap={{scale: 0.95}}
                                            onClick={() => openBanModal(user)}
                                            className="flex items-center gap-2 px-4 py-2 bg-red-500/10 text-red-600 dark:text-red-400 rounded-lg hover:bg-red-500/20 transition-colors"
                                        >
                                            <i className="fas fa-lock h-5 w-5"/>
                                            <span>Забанить</span>
                                        </motion.button>
                                    )}
                                </td>
                            </motion.tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </motion.div>

                {loading && (
                    <div className="mt-8 text-center text-neutral-500 dark:text-neutral-400">
                        <i className="fas fa-spinner fa-spin mr-2"/>
                        Загрузка данных пользователей...
                    </div>
                )}
        </AdminLayout>
    );
};

export default AdminPanel;