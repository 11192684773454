import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [user, setUser] = useState(null);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data && typeof response.data === 'object') {
        setUser(response.data);
        setIsLoggedIn(true);
      } else {
        console.warn("Unexpected user data format:", response.data);
        handleLogout();
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      handleLogout();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) fetchUserData(token);

    const handleStorageChange = () => {
      if (!localStorage.getItem('token')) handleLogout();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    fetchUserData(token);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUser(null);
  };

  const handleLogout = () => {
    logout();
    window.location.reload(); // Для полного сброса состояния
  };

  return (
      <AuthContext.Provider value={{
        isLoggedIn,
        user,
        login,
        logout: handleLogout,
        setUser
      }}>
        {children}
      </AuthContext.Provider>
  );
};