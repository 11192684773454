import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import BackLink from "./BackLink";
import { debounce } from "lodash"; // Добавление debounce
import { AiOutlineClose } from "react-icons/ai"; // Импортируем иконку крестика

const Search = () => {
    const [searchResults, setSearchResults] = useState({ users: [], posts: [], groups: [] });
    const [searchValue, setSearchValue] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { width } = useWindowSize();
    const [showSearchResults, setShowSearchResults] = useState(false);

    const handleSearchDebounced = debounce(async () => {
        if (searchValue.trim() === '') {
            setSearchResults({ users: [], posts: [], groups: [] });
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/search`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                params: {
                    query: searchValue,
                    category: selectedCategory,
                },
            });
            setSearchResults(response.data);
            setShowSearchResults(true);
        } catch (error) {
            console.error(error);
        }
    }, 500);

    useEffect(() => {
        handleSearchDebounced();
        return () => handleSearchDebounced.cancel();
    }, [searchValue, selectedCategory]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/search/categories`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setCategories(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCategories();
    }, []);

    const categoryNames = {
        profile: 'Пользователи',
        posts: 'Посты',
        group: 'Группы',
    };

    const isEmptyResults = Object.values(searchResults).every(result => result.length === 0);

    const clearSearch = () => {
        setSearchValue('');
    };

    return (
        <div className="container mx-auto p-4 relative dark:bg-neutral-900 min-h-screen pt-16">
            {width < 768 && <BottomNav />}
            <BackLink />

            <div className="md:pt-4"> {/* Добавляем отступ для фиксированного заголовка */}
                <h2 className="md:block text-3xl font-extrabold text-gray-800 mb-5 dark:text-white">
                    Поиск
                </h2>

                {/* Поисковая строка */}
                <div className="sticky top-16 md:top-4 bg-white dark:bg-neutral-800 shadow-md py-4 px-4 z-10 rounded-full">
                    <form onSubmit={(e) => e.preventDefault()} className="flex items-center gap-2">
                        <input
                            type="text"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder="Поиск..."
                            className="flex-1 bg-gray-200 dark:bg-neutral-700 rounded-full px-4 py-2
                                text-gray-900 dark:text-white focus:outline-none focus:ring-2
                                focus:ring-violet-600 text-sm md:text-base"
                        />
                        {searchValue && (
                            <button
                                type="button"
                                onClick={clearSearch}
                                className="text-gray-500 dark:text-gray-300 hover:text-gray-800
                                    dark:hover:text-white transition"
                            >
                                <AiOutlineClose size={20} />
                            </button>
                        )}
                    </form>
                </div>

                {/* Категории с горизонтальной прокруткой */}
                <div className="px-4 py-4">
                    <div className="overflow-x-auto pb-2">
                        <div className="flex gap-2 w-max">
                            {categories.map((category) => (
                                <button
                                    key={category.id}
                                    onClick={() => handleCategoryClick(category.id)}
                                    className={`py-2 px-3 md:px-4 rounded-full font-medium transition text-sm
                                        ${
                                        selectedCategory === category.id
                                            ? 'bg-violet-600 text-white'
                                            : 'bg-gray-200 dark:bg-neutral-500 text-gray-800 dark:text-white hover:bg-gray-300 dark:hover:bg-neutral-600'
                                    }`}
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Результаты поиска */}
                    {showSearchResults ? (
                        isEmptyResults ? (
                            <p className="text-center text-gray-500 dark:text-gray-400 mt-8">
                                Ничего не найдено.
                            </p>
                        ) : (
                            <div className="space-y-4">
                                {Object.entries(searchResults).map(([key, results]) => (
                                    results.length > 0 && (
                                        <div key={key} className="mb-4">
                                            <h3 className="text-md font-semibold text-gray-800 dark:text-white mb-2">
                                                {categoryNames[key] || key.charAt(0).toUpperCase() + key.slice(1)}
                                            </h3>
                                            <div className="grid gap-2">
                                                {results.map((result) => (
                                                    <Link
                                                        to={`/${key}/${result.id}`}
                                                        key={result.id}
                                                        className="block bg-white dark:bg-neutral-800
                                                            rounded-lg shadow-sm hover:shadow-md
                                                            transition p-3"
                                                    >
                                                        <div className="flex items-center gap-3">
                                                            {result.avatar && (key === 'profile') && (
                                                                <img
                                                                    src={result.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${result.avatar}` : 'https://ui-avatars.com/api/?name=${result.name}&background=random'}
                                                                    alt={result.name}
                                                                    className="w-8 h-8 rounded-full
                                                                        flex-shrink-0"
                                                                />
                                                            )}
                                                            <div>
                                                                <p className="text-sm font-medium text-gray-900
                                                                    dark:text-white line-clamp-1">
                                                                    {result.name || result.title || result.description}
                                                                </p>
                                                                {result.created_at && (key === 'posts') && (
                                                                    <p className="text-xs text-gray-500
                                                                        dark:text-gray-400 mt-1">
                                                                        {new Date(result.created_at)
                                                                            .toLocaleDateString()}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        )
                    ) : (
                        <p className="text-center text-gray-500 dark:text-gray-400 mt-8 text-sm">
                            Начните поиск, чтобы увидеть результаты
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Search;