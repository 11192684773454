import React from 'react';
import {Link, NavLink} from "react-router-dom";

const AdminAside = () => {

    return (
        <aside
            className="w-64 h-[calc(100vh-100px)] mt-8 bg-white dark:bg-neutral-800 shadow-xl p-4 rounded-xl flex flex-col">
            <div className="flex items-center mb-8 pl-2">
                <i className="fas fa-user-shield text-purple-600 dark:text-purple-400 text-xl mr-3"/>
                <h2 className="text-xl font-bold text-neutral-800 dark:text-neutral-200">Админ-панель</h2>
            </div>

            <nav className="space-y-2 flex-1">
                <NavLink
                    to="/admin"
                    end
                    className={({isActive}) =>
                        `flex items-center p-3 rounded-lg transition-colors ${
                            isActive
                                ? 'bg-purple-100 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400'
                                : 'hover:bg-neutral-100 dark:hover:bg-neutral-700 text-neutral-600 dark:text-neutral-300'
                        }`
                    }
                >
                    <i className="fas fa-users mr-3 w-5 text-center"/>
                    Пользователи
                </NavLink>

                <NavLink
                    to="/admin/music"
                    className={({isActive}) =>
                        `flex items-center p-3 rounded-lg transition-colors ${
                            isActive
                                ? 'bg-purple-100 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400'
                                : 'hover:bg-neutral-100 dark:hover:bg-neutral-700 text-neutral-600 dark:text-neutral-300'
                        }`
                    }
                >
                    <i className="fas fa-music mr-3 w-5 text-center"/>
                    Музыка
                </NavLink>

                <NavLink
                    to="/admin/stats"
                    className={({isActive}) =>
                        `flex items-center p-3 rounded-lg transition-colors ${
                            isActive
                                ? 'bg-purple-100 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400'
                                : 'hover:bg-neutral-100 dark:hover:bg-neutral-700 text-neutral-600 dark:text-neutral-300'
                        }`
                    }
                >
                    <i className="fas fa-chart-bar mr-3 w-5 text-center"/>
                    Статистика
                </NavLink>

                <NavLink
                    to="/admin/logs"
                    className={({isActive}) =>
                        `flex items-center p-3 rounded-lg transition-colors ${
                            isActive
                                ? 'bg-purple-100 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400'
                                : 'hover:bg-neutral-100 dark:hover:bg-neutral-700 text-neutral-600 dark:text-neutral-300'
                        }`
                    }
                >
                    <i className="fas fa-clipboard-list mr-3 w-5 text-center"/>
                    Журналы
                </NavLink>

                <NavLink
                    to="/admin/settings"
                    className={({isActive}) =>
                        `flex items-center p-3 rounded-lg transition-colors ${
                            isActive
                                ? 'bg-purple-100 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400'
                                : 'hover:bg-neutral-100 dark:hover:bg-neutral-700 text-neutral-600 dark:text-neutral-300'
                        }`
                    }
                >
                    <i className="fas fa-cog mr-3 w-5 text-center"/>
                    Настройки
                </NavLink>
            </nav>

            <div className="pt-4 border-t border-neutral-200 dark:border-neutral-700">
                <Link
                    className="w-full flex items-center p-3 text-neutral-600 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-lg transition-colors"
                    to="/"
                >
                    <i className="fas fa-arrow-left mr-3 w-5 text-center"/>
                    На главную
                </Link>
            </div>
        </aside>
    )
}

export default AdminAside;