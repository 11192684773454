import React, { useState, useEffect } from "react";
import BackLink from "./BackLink";
import { Link } from "react-router-dom";
import { usePlayer } from "../context/PlayerContext";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";
import BottomNav from "./BottomNav";
import useWindowSize from "../hooks/useWindowSize";
import AlbumList from "./AlbumList";

const TrackList = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('favorites'); // По умолчанию выбрана вкладка "Мне нравится"
    const [processingFavorite, setProcessingFavorite] = useState(null);
    const { tracks, setTracks } = usePlayer();

    useEffect(() => {
        const fetchTracks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Не удалось загрузить треки");
                const data = await response.json();
                setTracks(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTracks();
    }, [setTracks]);

    const toggleFavorite = async (trackId) => {
        setProcessingFavorite(trackId);
        try {
            const track = tracks.find(t => t.id === trackId);
            const method = track.is_favorite ? 'DELETE' : 'POST';

            const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks/${trackId}/favorite`, {
                method,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (!response.ok) throw new Error('Ошибка при обновлении избранного');
            setTracks(tracks.map(t =>
                t.id === trackId ? { ...t, is_favorite: !t.is_favorite } : t
            ));
        } catch (err) {
            console.error(err);
        } finally {
            setProcessingFavorite(null);
        }
    };

    return (
        <TrackListContent
            loading={loading}
            error={error}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            processingFavorite={processingFavorite}
            toggleFavorite={toggleFavorite}
        />
    );
};

const TrackListContent = ({
                              loading,
                              error,
                              activeTab,
                              setActiveTab,
                              processingFavorite,
                              toggleFavorite
                          }) => {
    const { playTrack, currentTrack, isPlaying, tracks } = usePlayer();
    const displayedTracks = activeTab === 'favorites'
        ? tracks.filter(t => t.is_favorite)
        : tracks;

    const { width } = useWindowSize();

    return (
        <div className="p-4 dark:bg-neutral-900 rounded-lg w-full container mx-auto pb-28 pt-16">
            {width < 768 && <BottomNav/>}
            <BackLink/>
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-3xl font-extrabold text-neutral-800 dark:text-white">Музыка</h2>
                <Link
                    to="/upload-track"
                    className="flex items-center gap-2 px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800/50 rounded-full transition-colors duration-150 group/button"
                >
                    <div className="relative h-8 w-8 flex items-center justify-center">
                        <div
                            className="absolute inset-0 bg-purple-500/10 rounded-full scale-50 group-hover/button:scale-100 transition-transform"/>
                        <i className="fas fa-plus text-purple-500 group-hover/button:text-purple-600 transition-colors duration-150"/>
                    </div>
                    <span className="text-lg font-medium text-neutral-700 dark:text-neutral-300">
                        Добавить трек
                    </span>
                </Link>
            </div>

            {/* Переключатель вкладок */}
            <div className="flex mb-8 gap-1 relative">
                <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-gray-200 dark:bg-neutral-700 rounded-full"/>
                <button
                    onClick={() => setActiveTab('favorites')}
                    className={`relative px-4 py-2 text-sm sm:text-md font-medium transition-colors ${
                        activeTab === 'favorites' ? 'text-purple-500' : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                    }`}
                >
                    Мне нравится
                    {activeTab === 'favorites' && (
                        <motion.div
                            className="absolute bottom-0 left-0 right-0 h-[4px] rounded-full bg-purple-500"
                            layoutId="underline"
                            transition={{type: "spring", bounce: 0.2, duration: 0.6}}
                        />
                    )}
                </button>
                <button
                    onClick={() => setActiveTab('all')}
                    className={`relative px-4 py-2 text-sm sm:text-md font-medium transition-colors ${
                        activeTab === 'all' ? 'text-purple-500' : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                    }`}
                >
                    Все треки
                    {activeTab === 'all' && (
                        <motion.div
                            className="absolute bottom-0 left-0 right-0 h-[4px] rounded-full bg-purple-500"
                            layoutId="underline"
                            transition={{type: "spring", bounce: 0.2, duration: 0.6}}
                        />
                    )}
                </button>
                <button
                    onClick={() => setActiveTab('albums')}
                    className={`relative px-4 py-2 text-sm sm:text-md font-medium transition-colors ${
                        activeTab === 'albums' ? 'text-purple-500' : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                    }`}
                >
                    Альбомы
                    {activeTab === 'albums' && (
                        <motion.div
                            className="absolute bottom-0 left-0 right-0 h-[4px] rounded-full bg-purple-500"
                            layoutId="underline"
                            transition={{type: "spring", bounce: 0.2, duration: 0.6}}
                        />
                    )}
                </button>
            </div>


            {/* Контент в зависимости от активной вкладки */}
            {loading ? (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="relative">
                        <div className="absolute inset-0 bg-purple-500/10 blur-xl animate-pulse rounded-full"/>
                        <ClipLoader
                            color="rgba(139,92,246,0.8)"
                            loading={loading}
                            size={70}
                            cssOverride={{
                                borderWidth: '3px',
                                borderLeftColor: 'transparent'
                            }}
                        />
                    </div>
                </div>
            ) : error ? (
                <p className="text-center text-red-500">{error}</p>
            ) : activeTab === "albums" ? (
                <AlbumList/> // Отображение списка альбомов
            ) : displayedTracks.length === 0 ? (
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    className="flex flex-col items-center justify-center h-64"
                >
                    <div className="relative">
                        <i className="fas fa-heart-broken text-6xl text-purple-500 opacity-50"/>
                    </div>
                    <p className="mt-4 text-gray-500 text-center">
                        {activeTab === 'favorites'
                            ? "У вас пока нет избранных треков"
                            : "Нет доступных треков"}
                    </p>
                </motion.div>
            ) : (
                <div className="overflow-y-auto max-h-[60vh]">
                    <ul className="grid grid-cols-1 gap-2">
                        {displayedTracks.map((track) => {
                            const isCurrent = currentTrack?.id === track.id;
                            return (
                                <li
                                    key={track.id}
                                    className={`p-3 flex items-center space-x-4 rounded-lg transition-all duration-200 ${
                                        isCurrent
                                            ? "border-l-4 border-purple-500 bg-white dark:bg-neutral-800 shadow-md"
                                            : "bg-white dark:bg-neutral-800 hover:bg-gray-100 dark:hover:bg-neutral-700"
                                    }`}
                                >
                                    <img
                                        src={
                                            track.album_art
                                                ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}`
                                                : "/placeholder.png"
                                        }
                                        alt={`${track.title} Album Art`}
                                        className="w-12 h-12 rounded-xl shadow-sm object-cover"
                                    />
                                    <div className="flex-1 overflow-hidden min-w-0">
                                        <h3 className="font-semibold text-lg truncate text-neutral-800 dark:text-white">
                                            {track.title}
                                        </h3>
                                        <p className="text-gray-500 dark:text-gray-400 text-sm truncate">
                                            {track.artist}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <button
                                            onClick={() => toggleFavorite(track.id)}
                                            disabled={processingFavorite === track.id}
                                            className={`w-10 h-10 flex items-center justify-center rounded-full transition-colors ${
                                                track.is_favorite ? 'text-red-500 hover:text-red-600' : 'text-gray-400 dark:text-gray-500 hover:text-gray-500'
                                            }`}
                                        >
                                            {processingFavorite === track.id ? (
                                                <ClipLoader size={18} color="#ef4444"/>
                                            ) : (
                                                <i className={`fa-heart ${track.is_favorite ? 'fas' : 'far'} text-lg`}/>
                                            )}
                                        </button>
                                        <button
                                            className="w-10 h-10 flex items-center justify-center bg-gray-200 dark:bg-neutral-700 text-purple-500 rounded-full shadow-sm hover:bg-gray-300 dark:hover:bg-neutral-600 transition-all"
                                            onClick={() => playTrack(track)}
                                        >
                                            <i className={`fas ${isCurrent && isPlaying ? "fa-pause" : "fa-play"}`}/>
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TrackList;