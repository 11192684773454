import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Post from './Post';
import { ClipLoader } from 'react-spinners';
import { motion, AnimatePresence } from 'framer-motion';

function PostList({ posts = [], setPosts, userId, userPreferences = {}, groupId = null }) {
  const [loading, setLoading] = useState(true);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          groupId,
          userId,
        },
      });

      if (Array.isArray(response.data)) {
        const userPosts = response.data.filter(post => post.userId === userId);

        const postsWithScores = userPosts.map((post) => {
          const postAgeInHours = (Date.now() - new Date(post.createdAt).getTime()) / 1000 / 3600;
          return { ...post, postAgeInHours };
        });

        const sortedPosts = postsWithScores.sort((a, b) => {
          const aCategoryWeight = userPreferences?.categories?.includes(a.category) ? 1 : 0;
          const bCategoryWeight = userPreferences?.categories?.includes(b.category) ? 1 : 0;

          const aRecentCommentWeight = a.lastCommentAt ? (Date.now() - new Date(a.lastCommentAt).getTime()) / 1000 / 3600 : 1000;
          const bRecentCommentWeight = b.lastCommentAt ? (Date.now() - new Date(b.lastCommentAt).getTime()) / 1000 / 3600 : 1000;

          const aScore =
              (a.views * 0.4) +
              (a.likes.length * 0.3) +
              (a.comments.length * 0.2) +
              (aCategoryWeight * 0.5) -
              (a.postAgeInHours * 0.05) -
              (aRecentCommentWeight * 0.05);

          const bScore =
              (b.views * 0.4) +
              (b.likes.length * 0.3) +
              (b.comments.length * 0.2) +
              (bCategoryWeight * 0.5) -
              (b.postAgeInHours * 0.05) -
              (bRecentCommentWeight * 0.05);

          return bScore - aScore;
        });

        setPosts(sortedPosts);
      } else {
        console.error('Unexpected response data format:', response.data);
      }
    } catch (error) {
      console.error('Ошибка при получении постов!', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [userId, groupId]);

  if (loading) {
    return (
        <div className="flex items-center justify-center min-h-screen">
          <ClipLoader color="#6B46C1" loading={loading} size={75} />
        </div>
    );
  }

  if (posts.length === 0) {
    return (
        <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-4">
          <p className="text-center text-gray-700 dark:text-white">Нет постов для отображения.</p>
        </div>
    );
  }


  return (
      <div className="container min-h-screen max-w-4xl dark:bg-neutral-900">
        <div className="space-y-4">
          <AnimatePresence>
            {posts.map((post, index) => (
                <motion.div
                    key={post.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.3 }}
                    style={{
                      marginBottom: index === posts.length - 1 ? '7rem' : '',
                      display: 'block !important'
                    }}
                >
                  <Post post={post} />
                </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
  );
}

export default PostList;
