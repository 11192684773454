import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { UserPlus, X, Users } from 'lucide-react';
import useWindowSize from "../../hooks/useWindowSize";
import Skeleton from 'react-loading-skeleton';

function FriendSuggestions() {
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { width } = useWindowSize();

    useEffect(() => {
        const fetchSuggestions = async () => {
            const token = localStorage.getItem('token');
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/friend-recommendations`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setSuggestions(data);
            } catch (e) {
                setError('Не удалось загрузить рекомендации');
            } finally {
                setLoading(false);
            }
        };
        fetchSuggestions();
    }, []);

    const handleRemoveSuggestion = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/friend-recommendations/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setSuggestions(prev => prev.filter(s => s.id !== id));
        } catch {
            alert('Не удалось удалить рекомендацию');
        }
    };

    const handleAddFriend = async (id) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/friends`,
                { friend_id: id },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            setSuggestions(prev => prev.filter(s => s.recommended_friend.id !== id));
        } catch (error) {
            console.error('Ошибка:', error);
            alert(error.response?.data?.message || 'Ошибка при отправке заявки');
        }
    };

    if (error) return (
        <div className="p-4 bg-red-50 dark:bg-red-900/20 rounded-xl text-red-600 dark:text-red-400">
            {error}
        </div>
    );

    return (
        <div className="relative isolate bg-white dark:bg-neutral-800/50 rounded-2xl shadow-sm p-2 mt-4"
             style={{
                 zIndex: 0, // Явно понижаем
                 position: 'relative'
             }}
        >
            <div className="flex items-center space-x-2 mb-6">
                <div className="p-2 bg-violet-100 dark:bg-neutral-700 rounded-xl">
                    <Users className="w-6 h-6 text-violet-600 dark:text-violet-400"/>
                </div>
                <h2 className="text-xl font-semibold dark:text-white">
                    Возможно вы знакомы
                </h2>
            </div>

            {loading ? (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="p-4 rounded-xl bg-gray-50 dark:bg-neutral-700/30">
                            <Skeleton circle height={96} className="mb-3"/>
                            <Skeleton count={2} className="mb-2"/>
                            <Skeleton height={32}/>
                        </div>
                    ))}
                </div>
            ) : suggestions.length === 0 ? (
                <div className="text-center py-6">
                    <p className="text-gray-500 dark:text-neutral-400">
                        Нет доступных рекомендаций
                    </p>
                </div>
            ) : (
                <motion.div
                    className="grid grid-cols-2 md:grid-cols-3 gap-4"
                    initial="hidden"
                    animate="visible"
                >
                    <AnimatePresence>
                        {suggestions.map((suggestion) => (
                            <motion.div
                                key={suggestion.id}
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, scale: 0.9}}
                                className="group relative bg-white dark:bg-neutral-700/20 hover:bg-gray-50 dark:hover:bg-neutral-700/40 rounded-xl p-4 transition-all"
                            >
                                <button
                                    onClick={() => handleRemoveSuggestion(suggestion.id)}
                                    className="absolute top-3 right-3 p-1 text-gray-400 hover:text-red-500 rounded-full transition-colors"
                                >
                                    <X className="w-5 h-5"/>
                                </button>

                                <div className="flex flex-col items-center">
                                    <img
                                        src={suggestion.recommended_friend.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${suggestion.recommended_friend.avatar}` : `https://ui-avatars.com/api/?name=${suggestion.recommended_friend.name}&background=random`}
                                        alt={suggestion.recommended_friend.name}
                                        className="w-24 h-24 rounded-full object-cover border-2 border-white dark:border-neutral-800 shadow-lg mb-4"
                                    />
                                    <h3 className="font-medium text-center dark:text-white">
                                        {suggestion.recommended_friend.name}
                                    </h3>

                                    {suggestion.mutual_friends_count > 0 && (
                                        <p className="text-sm text-gray-500 dark:text-neutral-400 mt-1">
                                            {suggestion.mutual_friends_count} общих друзей
                                        </p>
                                    )}

                                    <button
                                        onClick={() => handleAddFriend(suggestion.recommended_friend.id)}
                                        className="mt-4 w-full flex items-center justify-center gap-2 px-4 py-2 bg-violet-500/10 hover:bg-violet-500/20 text-violet-600 dark:text-violet-400 rounded-lg transition-colors"
                                    >
                                        <UserPlus className="w-4 h-4"/>
                                        <span>Добавить</span>
                                    </button>
                                </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </motion.div>
            )}
        </div>
    );
}

export default FriendSuggestions;