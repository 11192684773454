import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import BottomNav from "./BottomNav";
import CustomSkeleton from "./CustomSkeleton";
import useWindowSize from "../hooks/useWindowSize";
import { motion } from 'framer-motion';
import ConfirmLogout from "./ConfirmLogout";
import {
    UserCircle,
    Users2,
    Compass,
    Music,
    ClipboardList,
    Grid2x2Plus,
    SlidersHorizontal,
    QrCode,
    Palette,
    LogOut,
    ChevronRight
} from "lucide-react";

function MobileMenu() {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showConfirmLogout, setShowConfirmLogout] = useState(false);
    const { isLoggedIn, logout } = useContext(AuthContext);
    const { width } = useWindowSize();

    const navigate = useNavigate();

    if (width > 768) {
        navigate('/')
    }

    const fetchUser = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUser(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке профиля!', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const changeTheme = () => {
        document.documentElement.classList.toggle('dark');
        const currentTheme = document.documentElement.classList.contains('dark') ? 'dark' : 'light';
        localStorage.setItem('theme', currentTheme);
    };

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, []);

    const menuItems = [
        { icon: UserCircle, label: "Профиль", link: "/profile" },
        { icon: Users2, label: "Друзья", link: "/friends" },
        { icon: Compass, label: "Группы", link: "/groups", notification: 1 },
        { icon: Music, label: "Музыка", link: "/music" },
        { icon: ClipboardList, label: "Посты", link: "/posts" },
        { icon: Grid2x2Plus, label: "Сервисы", link: "/services" },
        { icon: SlidersHorizontal, label: "Настройки", link: "/settings" },
    ];

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleLogoutClick = () => {
        setShowConfirmLogout(true);
    };

    const closeConfirmLogout = () => {
        setShowConfirmLogout(false);
    };

    const confirmLogout = () => {
        setShowConfirmLogout(false);
        handleLogout();
    };

    return (
        <motion.div
            className="flex flex-col w-full h-screen bg-white text-gray-900 dark:bg-neutral-900 dark:text-white p-4 overflow-y-auto md:hidden pt-16"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <div className="flex items-center mb-6">
                <div className="flex items-center space-x-4 p-3 w-full bg-gray-100 dark:bg-neutral-800 rounded-lg shadow-sm">
                    {isLoading ? (
                        <CustomSkeleton circle={true} width={48} height={48}/>
                    ) : (
                        <img
                            src={
                                user?.avatar
                                    ? `${process.env.REACT_APP_BASE_URL || ""}/storage/${user?.avatar}`
                                    : "https://ui-avatars.com/api/?name=" + user?.name + "&background=random"
                            }
                            alt="User Profile"
                            className="w-12 h-12 rounded-full object-cover border border-gray-300 dark:border-neutral-700"
                            onError={(e) => e.target.src = "https://via.placeholder.com/150"}
                        />
                    )}
                    <div>
                        {isLoading ? (
                            <CustomSkeleton width={160} height={24}/>
                        ) : (
                            <p className="text-lg font-semibold">
                                {isLoggedIn ? user?.name : "Гость"}
                            </p>
                        )}
                        {isLoading ? (
                            <CustomSkeleton width={140} height={16}/>
                        ) : (
                            <Link
                                to="/profile"
                                className="text-sm text-gray-500 dark:text-gray-400 items-center flex"
                            >
                                {isLoggedIn ? "Перейти в профиль" : "Войти в систему"}
                                <ChevronRight className="ml-1 text-sm inline-block w-4 h-4" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>

            <div className="space-y-2">
                {menuItems.map((item, index) => (
                    <motion.div
                        key={index}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        <Link
                            to={item.link}
                            className="flex items-center text-gray-700 hover:text-purple-600 dark:text-gray-300 dark:hover:text-white transition-all duration-300 p-2 rounded-lg"
                        >
                            <item.icon className="mr-3 w-6 h-6 stroke-current" />
                            <span className="font-semibold flex-1">{item.label}</span>
                        </Link>
                    </motion.div>
                ))}
            </div>

            <div className="mt-2">
                <Link
                    to="/qr-login"
                    className="flex items-center text-gray-700 hover:text-purple-600 dark:text-gray-300 dark:hover:text-white transition-all duration-300 p-2 rounded-lg"
                >
                    <QrCode className="mr-3 w-6 h-6 stroke-current" />
                    <span className="font-semibold flex-1">Сканировать QR-код</span>
                </Link>
            </div>

            <div className="mt-2">
                <button
                    onClick={changeTheme}
                    className="flex items-center text-gray-700 hover:text-purple-600 dark:text-gray-300 dark:hover:text-white transition-all duration-300 p-2 rounded-lg"
                >
                    <Palette className="mr-3 w-6 h-6 stroke-current" />
                    <span className="font-semibold flex-1">Сменить тему</span>
                </button>
            </div>

            {isLoggedIn && (
                <div className="mt-4">
                    <button
                        onClick={handleLogoutClick}
                        className="w-full flex items-center ml-2 text-red-600 hover:text-red-800 transition-all duration-300 font-semibold"
                    >
                        <LogOut className="mr-3 w-6 h-6 stroke-current" />
                        Выйти
                    </button>
                </div>
            )}

            <div className="fixed bottom-0 left-0 right-0">
                <BottomNav/>
            </div>
            <ConfirmLogout
                isOpen={showConfirmLogout}
                onClose={closeConfirmLogout}
                onConfirm={confirmLogout}
            />
        </motion.div>
    );
}

export default MobileMenu;