import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { UserPlus, Check, X, Clock } from 'lucide-react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';

function FriendRequests() {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isLoggedIn } = useContext(AuthContext);

    const fetchRequests = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/friend-requests`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setRequests(data);
        } catch (error) {
            toast.error('Ошибка загрузки заявок');
        } finally {
            setLoading(false);
        }
    };

    const handleResponse = async (id, action) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/friends/${action}/${id}`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setRequests(prev => prev.filter(r => r.id !== id));
            toast.success(action === 'accept' ? 'Заявка принята' : 'Заявка отклонена');
        } catch (error) {
            toast.error('Ошибка обработки запроса');
        }
    };

    useEffect(() => {
        if (isLoggedIn) fetchRequests();
    }, [isLoggedIn]);

    if (!isLoggedIn) return null;

    return (
        <div className="bg-white/50 dark:bg-neutral-800/50 backdrop-blur-sm rounded-2xl shadow-sm p-2 mt-4">
            <div className="flex items-center gap-3 mb-6">
                <div className="p-2 bg-violet-100 dark:bg-neutral-700 rounded-xl">
                    <UserPlus className="w-6 h-6 text-violet-600 dark:text-violet-400" />
                </div>
                <h2 className="text-xl font-semibold dark:text-white">Заявки в друзья</h2>
            </div>

            {loading ? (
                <div className="space-y-4">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="p-4 bg-gray-50 dark:bg-neutral-700/30 rounded-xl">
                            <Skeleton circle height={48} className="mb-2" />
                            <Skeleton count={2} />
                        </div>
                    ))}
                </div>
            ) : requests.length === 0 ? (
                <div className="text-center py-6">
                    <div className="inline-block p-4 bg-gray-100 dark:bg-neutral-700 rounded-full mb-4">
                        <Clock className="w-8 h-8 text-gray-400" />
                    </div>
                    <p className="text-gray-500 dark:text-neutral-400">
                        Нет новых заявок
                    </p>
                </div>
            ) : (
                <motion.ul className="space-y-3">
                    <AnimatePresence>
                        {requests.map(request => (
                            <motion.li
                                key={request.id}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, scale: 0.9 }}
                                className="group bg-white dark:bg-neutral-700/20 hover:bg-gray-50 dark:hover:bg-neutral-700/40 rounded-xl p-2 transition-all"
                            >
                                <div className="flex items-center justify-between gap-4">
                                    <div className="flex items-center gap-3 flex-1 min-w-0">
                                        <img
                                            src={request.sender.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${request.sender.avatar}` : `https://ui-avatars.com/api/?name=${request.sender.name}&background=random`}
                                            alt={request.sender.name}
                                            className="w-12 h-12 rounded-full object-cover border-2 border-white dark:border-neutral-800"
                                        />
                                        <div className="flex-1 min-w-0">
                                            <h3 className="font-medium truncate dark:text-white">
                                                {request.sender.name}
                                            </h3>
                                            <p className="text-sm text-gray-500 dark:text-neutral-400 truncate">
                                                {request.sender.email}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <button
                                            onClick={() => handleResponse(request.id, 'accept')}
                                            className="p-2 hover:bg-green-50 dark:hover:bg-green-900/20 rounded-lg text-green-600 dark:text-green-400"
                                        >
                                            <Check className="w-5 h-5" />
                                        </button>
                                        <button
                                            onClick={() => handleResponse(request.id, 'reject')}
                                            className="p-2 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg text-red-500 dark:text-red-400"
                                        >
                                            <X className="w-5 h-5" />
                                        </button>
                                    </div>
                                </div>
                            </motion.li>
                        ))}
                    </AnimatePresence>
                </motion.ul>
            )}
        </div>
    );
}

export default FriendRequests;