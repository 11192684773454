import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';
import {
    Home,
    Search,
    MessageCircle,
    Bell,
    Menu
} from "lucide-react";

const useUnreadMessages = () => {
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

    const fetchUnreadMessages = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/unread_count`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUnreadMessagesCount(response.data.unread_count || 0);
        } catch (error) {
            console.error('Error fetching unread messages count', error);
        }
    }, []);

    useEffect(() => {
        fetchUnreadMessages();
        const intervalId = setInterval(fetchUnreadMessages, 60000);
        return () => clearInterval(intervalId);
    }, [fetchUnreadMessages]);

    return unreadMessagesCount;
};

const BottomNav = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const location = useLocation();
    const unreadMessagesCount = useUnreadMessages();

    const items = useMemo(() => [
        { icon: Home, to: "/", name: "home" },
        { icon: Search, to: "/search", name: "search" },
        { icon: MessageCircle, to: "/messages", name: "messages", count: unreadMessagesCount },
        { icon: Bell, to: "/notifications", name: "notifications" },
        { icon: Menu, to: "/menu", name: "menu" },
    ], [unreadMessagesCount]);

    useEffect(() => {
        const currentPath = location.pathname;
        const currentIndex = items.findIndex(item => item.to === currentPath);
        setActiveIndex(currentIndex);
    }, [location.pathname, items]);

    const getNavItemClasses = useCallback((isActive) =>
            `relative flex items-center justify-center w-14 h-10 rounded-full transition-all duration-300 cursor-pointer 
        ${isActive ? 'bg-gray-200 dark:bg-neutral-700' : 'bg-transparent hover:bg-gray-100 dark:hover:bg-neutral-700'}`
        , []);

    return (
        <div className="bg-white p-2 rounded-t-2xl shadow-lg fixed bottom-0 left-0 right-0 flex justify-center dark:bg-neutral-800 z-50">
            <div className="flex space-x-4">
                {items.map((item, index) => {
                    const isActive = activeIndex === index;
                    const IconComponent = item.icon;

                    return (
                        <div
                            key={item.name}
                            className={getNavItemClasses(isActive)}
                            onClick={() => setActiveIndex(index)}
                        >
                            <Link to={item.to} className="flex items-center justify-center relative">
                                <span className={`${isActive ? 'text-violet-500' : 'text-gray-500 dark:text-gray-400'}`}>
                                    <IconComponent
                                        className="w-6 h-6 stroke-current"
                                        strokeWidth={1.5}
                                    />
                                    {item.count > 0 && (
                                        <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
                                            {item.count}
                                        </span>
                                    )}
                                </span>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BottomNav;