import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePlayer } from "../context/PlayerContext";
import BackLink from "./BackLink";
import { motion } from "framer-motion";
import { ClipLoader } from "react-spinners";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";

const AlbumDetails = () => {
    const { id } = useParams();
    const { playTrack, currentTrack, isPlaying } = usePlayer();
    const [album, setAlbum] = useState(null);
    const [loading, setLoading] = useState(true);
    const { width } = useWindowSize();

    useEffect(() => {
        const fetchAlbum = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/albums/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                const data = await response.json();
                setAlbum(data);
            } catch (err) {
                console.error("Ошибка загрузки альбома:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchAlbum();
    }, [id]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen pt-16">
                <ClipLoader
                    color="#8b5cf6"
                    loading={loading}
                    size={50}
                    cssOverride={{
                        borderWidth: '3px',
                        borderLeftColor: 'transparent'
                    }}
                />
            </div>
        );
    }

    if (!album) {
        return <p className="text-center text-gray-500">Альбом не найден</p>;
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="p-4 dark:bg-neutral-900 rounded-lg w-full container mx-auto pb-36 pt-16"
        >
            <BackLink />
            {width < 768 && <BottomNav/>}
            {/* Заголовок и обложка альбома */}
            <div className="flex flex-col md:flex-row items-center space-x-4 mt-4">
                <img
                    src={album.album_art ? `${process.env.REACT_APP_BASE_URL}/storage/${album.album_art}` : 'placeholder.png'}
                    alt={album.title}
                    className="w-52 h-52 rounded-lg object-cover shadow-lg"
                />
                <div className={`mt-4 md:mt-0 ${width < 768 ? 'text-center' : ''}`}>
                    <h2 className="text-3xl font-bold text-neutral-800 dark:text-white">{album.title}</h2>
                    <p className="text-gray-500 dark:text-gray-400">{album.artist}</p>
                    <p className="text-gray-500 dark:text-gray-400">{album.tracks.length} треков</p>
                </div>
            </div>

            {/* Список треков */}
            <ul className="mt-6 space-y-4">
                {album.tracks.map((track, index) => {
                    const isCurrent = currentTrack?.id === track.id;
                    return (
                        <motion.li
                            key={track.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            className={`p-3 flex items-center space-x-4 rounded-lg transition-all duration-200 ${
                                isCurrent
                                    ? "border-l-4 border-purple-500 bg-white dark:bg-neutral-800 shadow-md"
                                    : "bg-white dark:bg-neutral-800 hover:bg-gray-100 dark:hover:bg-neutral-700"
                            }`}
                        >
                            <img
                                src={track.album_art ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` : "/placeholder.png"}
                                alt={`${track.title} Album Art`}
                                className="w-12 h-12 rounded-xl shadow-sm object-cover"
                            />
                            <div className="flex-1 overflow-hidden min-w-0">
                                <h3 className="font-semibold text-lg truncate text-neutral-800 dark:text-white">{track.title}</h3>
                                <p className="text-gray-500 dark:text-gray-400 text-sm truncate">{track.artist}</p>
                            </div>
                            <button
                                className="w-10 h-10 flex items-center justify-center bg-gray-200 dark:bg-neutral-700 text-purple-500 rounded-full shadow-sm hover:bg-gray-300 dark:hover:bg-neutral-600 transition-all"
                                onClick={() => playTrack(track)}
                            >
                                <i className={`fas ${isCurrent && isPlaying ? "fa-pause" : "fa-play"}`} />
                            </button>
                        </motion.li>
                    );
                })}
            </ul>

            {/* Год выхода альбома */}
            <p className="text-center text-gray-500 dark:text-gray-400 mt-8">
                Год выпуска: {album.created_at && new Date(album.created_at).getFullYear()}
            </p>
        </motion.div>
    );
};

export default AlbumDetails;