import React, { createContext, useState, useContext, useRef, useEffect } from "react";

const PlayerContext = createContext();

export const usePlayer = () => useContext(PlayerContext);

export const PlayerProvider = ({ children, tracks }) => {
    const audioRef = useRef(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [buffered, setBuffered] = useState(0);
    const [isShuffling, setIsShuffling] = useState(false);
    const [isRepeating, setIsRepeating] = useState(false);
    const [tracksState, setTracksState] = useState(tracks || []);

    const isRepeatingRef = useRef(isRepeating);
    const isShufflingRef = useRef(isShuffling);
    const tracksRef = useRef(tracksState);
    const currentTrackRef = useRef(null);

    useEffect(() => {
        tracksRef.current = tracksState;
    }, [tracksState]);

    useEffect(() => {
        if (tracks) {
            setTracksState(tracks);
        }
    }, [tracks]);
    useEffect(() => {
        isRepeatingRef.current = isRepeating;
        isShufflingRef.current = isShuffling;
    }, [isRepeating, isShuffling]);

    useEffect(() => {
        const audio = audioRef.current;

        const handleEnded = () => {
            if (isRepeatingRef.current) {
                audio.currentTime = 0;
                audio.play().catch(console.error);
            } else {
                skipToNextTrack();
            }
        };

        audio.addEventListener("ended", handleEnded);
        return () => {
            audio.removeEventListener("ended", handleEnded);
        };
    }, []);

    const playTrack = (track) => {
        if (!track || !tracksRef.current.length) return;

        const audio = audioRef.current;

        if (currentTrackRef.current?.id === track.id) {
            return;
        }

        audio.pause();
        audio.currentTime = 0;

        currentTrackRef.current = track;

        audio.src = `${process.env.REACT_APP_BASE_URL}/storage/${track.file_path}`;
        audio.load();

        audio.onloadeddata = () => {
            audio.play()
                .then(() => setIsPlaying(true))
                .catch((err) => {
                    console.error("Ошибка воспроизведения:", err);
                    skipToNextTrack();
                });
        };

        audio.onerror = () => {
            console.error("Ошибка загрузки трека");
            skipToNextTrack();
        };
    };

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
            setIsPlaying(false);
        } else {
            audio.play().then(() => setIsPlaying(true)).catch(console.error);
        }
    };

    const setAudioVolume = (value) => {
        const audio = audioRef.current;
        audio.volume = value;
        setVolume(value);
    };

    const skipToNextTrack = () => {
        const tracks = tracksRef.current;
        if (!tracks?.length) return;

        const currentId = currentTrackRef.current?.id;
        let nextTrack;

        if (!currentTrackRef.current) {
            nextTrack = tracks[0];
        } else if (isShufflingRef.current) {
            const availableTracks = tracks.filter((t) => t.id !== currentId);
            nextTrack = availableTracks.length > 0
                ? availableTracks[Math.floor(Math.random() * availableTracks.length)]
                : tracks[0];
        } else {
            const currentIndex = tracks.findIndex((t) => t.id === currentId);
            nextTrack = currentIndex < tracks.length - 1 ? tracks[currentIndex + 1] : tracks[0];
        }

        if (nextTrack) {
            playTrack(nextTrack);
        }
    };

    const skipToPreviousTrack = () => {
        const tracks = tracksRef.current;
        if (!tracks?.length) return;

        const currentIndex = tracks.findIndex((t) => t.id === currentTrackRef.current?.id);
        const prevIndex = (currentIndex - 1 + tracks.length) % tracks.length;
        playTrack(tracks[prevIndex]);
    };

    const seekTrack = (time) => {
        const audio = audioRef.current;
        audio.currentTime = Math.min(time, duration - 0.1);
    };

    const toggleShuffle = () => setIsShuffling(!isShuffling);
    const toggleRepeat = () => setIsRepeating(!isRepeating);

    useEffect(() => {
        const audio = audioRef.current;

        const updateProgress = () => {
            if (audio.duration) {
                setProgress(audio.currentTime / audio.duration);
            }
            setBuffered(getBufferedProgress(audio));
        };

        const updateDuration = () => {
            setDuration(audio.duration || 0);
        };

        audio.addEventListener("timeupdate", updateProgress);
        audio.addEventListener("durationchange", updateDuration);
        audio.addEventListener("progress", updateProgress);

        return () => {
            audio.removeEventListener("timeupdate", updateProgress);
            audio.removeEventListener("durationchange", updateDuration);
            audio.removeEventListener("progress", updateProgress);
        };
    }, []);

    const getBufferedProgress = (audio) => {
        if (!audio.buffered.length) return 0;
        return audio.buffered.end(0) / audio.duration;
    };

    return (
        <PlayerContext.Provider
            value={{
                tracks: tracksState,
                setTracks: setTracksState,
                currentTrack: currentTrackRef.current, // Теперь currentTrack не сбрасывается
                isPlaying,
                progress,
                duration,
                buffered,
                volume,
                isShuffling,
                isRepeating,
                playTrack,
                togglePlayPause,
                setAudioVolume,
                skipToNextTrack,
                skipToPreviousTrack,
                seekTrack,
                toggleShuffle,
                toggleRepeat,
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
};
