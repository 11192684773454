import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import AddFriend from './AddFriend';
import FriendRequests from './FriendRequests';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import useWindowSize from '../../hooks/useWindowSize';
import Echo from 'laravel-echo';
import BackLink from "../BackLink";
import FriendSuggestions from "./FriendSuggestions";
import { motion, AnimatePresence } from 'framer-motion';
import { User, Trash2, ChevronRight, Circle, UserPlus, Clock } from 'lucide-react';
import BottomNav from "../BottomNav";
function FriendList() {
  const [friends, setFriends] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [friendToDelete, setFriendToDelete] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const fetchFriends = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFriends(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке друзей!', error);
    }
  };

  const openModal = (friendId) => {
    setFriendToDelete(friendId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFriendToDelete(null);
  };

  const handleDeleteFriend = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/friends/${friendToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFriends(friends.filter(friend => friend.friend.id !== friendToDelete));
      closeModal();
    } catch (error) {
      console.error('Ошибка при удалении друга!', error);
    }
  };

  const handleViewProfile = (friendId) => {
    navigate(`/profile/${friendId}`);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchFriends();

      if (window.Echo) {
        window.Echo.join('users')
            .here(users => {
              setFriends(friends => friends.map(friend => {
                const isOnline = users.find(user => user.id === friend.friend.id);
                return { ...friend, is_online: !!isOnline };
              }));
            })
            .joining(user => {
              setFriends(friends => friends.map(friend => {
                if (friend.friend.id === user.id) {
                  return { ...friend, is_online: true };
                }
                return friend;
              }));
            })
            .leaving(user => {
              setFriends(friends => friends.map(friend => {
                if (friend.friend.id === user.id) {
                  return { ...friend, is_online: false };
                }
                return friend;
              }));
            });
      }
    }
  }, [isLoggedIn]);


  return (
      <div className="container mx-auto p-2 dark:bg-neutral-900 h-screen pt-16 mb-20">
        {width < 768 && <BottomNav />}
        <BackLink/>
        <h2 className="text-3xl font-extrabold mb-6 dark:text-white">Друзья</h2>
        <div className="relative space-y-4">
          <AddFriend fetchFriends={fetchFriends}/>
          <FriendSuggestions className="relative z-0"/>
        </div>
        <FriendRequests/>
        <div className="lg:col-span-2">
          {friends.length === 0 ? (
              <div className="text-center p-8 rounded-xl bg-white dark:bg-neutral-800/50 mt-4">
                <div className="inline-block p-4 bg-gray-100 dark:bg-neutral-700 rounded-full mb-4">
                  <UserPlus className="w-8 h-8 text-gray-400"/>
                </div>
                <h3 className="text-xl font-medium dark:text-white">Друзей пока нет</h3>
                <p className="text-gray-500 dark:text-neutral-400 mt-2">
                  Начните добавлять друзей, используя кнопку выше
                </p>
              </div>
          ) : (
              <div className="space-y-4 mt-4">
                <h2 className="text-2xl font-bold dark:text-white">Ваши друзья</h2>
              <motion.ul className="space-y-2 pb-28">
                {friends.map(friend => (
                    <motion.li
                        key={friend.id}
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        className="group relative bg-white dark:bg-neutral-800/50 hover:bg-gray-50 dark:hover:bg-neutral-700/50 rounded-xl p-2 transition-all"
                    >
                      <div className="flex items-center justify-between">
                        {/* Левая часть - информация о друге */}
                        <div className="flex items-center space-x-3 flex-1 min-w-0">
                          <div className="relative">
                            <img
                                src={friend.friend.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${friend.friend.avatar}`
                                    : `https://ui-avatars.com/api/?name=${friend.friend.name}&background=random`}
                                alt={friend.friend.name}
                                className="w-12 h-12 rounded-xl object-cover border-2 border-white dark:border-neutral-800 shadow-sm"
                            />
                            <div className={`absolute -bottom-1 -right-1 w-3 h-3 rounded-full border-2 border-white dark:border-neutral-800 
                            ${friend.is_online ? 'bg-green-500' : 'bg-gray-300'}`}
                            />
                          </div>

                          <div className="flex-1 min-w-0">
                            <h3 className="font-semibold truncate dark:text-white">
                              {friend.friend.name}
                              <span className="text-gray-500 dark:text-neutral-400 font-normal ml-2">
                              @{friend.friend.id}
                            </span>
                            </h3>
                            <div className="flex items-center space-x-2 text-sm">
                              {friend.is_online ? (
                                  <>
                                    <Circle className="w-3 h-3 text-green-500" fill="currentColor"/>
                                    <span className="text-green-600 dark:text-green-400">Online</span>
                                  </>
                              ) : (
                                  <>
                                    <Clock className="w-3 h-3 text-gray-400"/>
                                    <span className="text-gray-500">Был(а) 2ч назад</span>
                                  </>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Правая часть - действия */}
                        <div
                            className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                          <button
                              onClick={() => handleViewProfile(friend.friend.id)}
                              className="p-2 hover:bg-gray-100 dark:hover:bg-neutral-600 rounded-lg text-gray-600 dark:text-neutral-300"
                          >
                            <ChevronRight className="w-5 h-5"/>
                          </button>

                          <button
                              onClick={() => openModal(friend.friend.id)}
                              className="p-2 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg text-red-500"
                          >
                            <Trash2 className="w-5 h-5"/>
                          </button>
                        </div>
                      </div>
                    </motion.li>
                ))}
              </motion.ul>
              </div>
          )}
        </div>
        <AnimatePresence>
          {modalIsOpen && (
              <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
              >
                <div className="bg-white dark:bg-neutral-800 rounded-2xl p-6 max-w-md w-full shadow-xl">
                  <div className="text-center mb-4">
                    <div className="mx-auto flex items-center justify-center w-12 h-12 bg-red-100 dark:bg-red-900/20 rounded-full">
                      <Trash2 className="w-6 h-6 text-red-600 dark:text-red-400" />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-center mb-2 dark:text-white">Удалить друга?</h3>
                  <p className="text-gray-600 dark:text-neutral-300 text-center mb-6">
                    Все совместные данные и история будут сохранены
                  </p>
                  <div className="flex justify-end gap-3">
                    <button
                        onClick={closeModal}
                        className="px-5 py-2.5 text-gray-500 dark:text-neutral-300 hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-lg"
                    >
                      Отмена
                    </button>
                    <button
                        onClick={handleDeleteFriend}
                        className="px-5 py-2.5 bg-red-500 text-white hover:bg-red-600 rounded-lg transition-colors"
                    >
                      Удалить
                    </button>
                  </div>
                </div>
              </motion.div>
          )}
        </AnimatePresence>
</div>
)
  ;
}

export default FriendList;
