import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Search, UserPlus } from 'lucide-react';
import toast from 'react-hot-toast';

const SuggestionList = ({ suggestions, onSelect }) => {
  return (
      <motion.ul
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          className="mt-2 w-full z-50 bg-white dark:bg-neutral-800 rounded-xl shadow-2xl max-h-72 overflow-y-auto"
          style={{
            zIndex: 9999, // Экстремальное значение для теста
            transform: 'translateZ(0)' // Создаем новый stacking context
          }}
      >
        {suggestions.map((suggestion) => (
            <li
                key={suggestion.id}
                className="p-2 cursor-pointer hover:bg-gray-50 border-b dark:border-neutral-700 dark:hover:bg-neutral-700/50 transition-colors"
                onClick={() => onSelect(suggestion)}
            >
              <div className="flex items-center space-x-3">
                <img
                    src={suggestion.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${suggestion.avatar}` : `https://ui-avatars.com/api/?name=${suggestion.name}&background=random`}
                    alt={suggestion.name}
                    className="w-10 h-10 rounded-full object-cover border-2 border-white dark:border-neutral-800"
                />
                <div>
                  <p className="font-medium dark:text-white">{suggestion.name}</p>
                  {suggestion.mutual_friends > 0 && (
                      <p className="text-sm text-gray-500 dark:text-neutral-400">
                        {suggestion.mutual_friends} общих друзей
                      </p>
                  )}
                </div>
              </div>
            </li>
        ))}
      </motion.ul>
  );
};

const AddFriend = ({ fetchFriends }) => {
  const [friendName, setFriendName] = useState('');
  const [friendId, setFriendId] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);

  const fetchSuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/friends/suggestions`, {
        params: { query },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSuggestions(data);
    } catch (error) {
      toast.error('Ошибка при поиске');
    }
  };

  const handleSelect = (suggestion) => {
    setFriendName(suggestion.name);
    setFriendId(suggestion.id);
    setSuggestions([]); // Очищаем suggestions после выбора
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!friendId) return toast.error('Выберите друга из списка');

    setIsLoading(true);
    try {
      await axios.post(
          `${process.env.REACT_APP_API_URL}/friends`,
          { friend_id: friendId },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      toast.success('Заявка отправлена!');
      setFriendName('');
      setFriendId(null);
      fetchFriends();
    } catch (error) {
      toast.error(error.response?.data?.error || 'Ошибка при отправке');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isLoggedIn) return null;

  return (
      <div className="relative bg-white/50 dark:bg-neutral-800/50 backdrop-blur-sm rounded-2xl shadow-sm p-2">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-violet-100 dark:bg-neutral-700 rounded-xl">
            <UserPlus className="w-6 h-6 text-violet-600 dark:text-violet-400" />
          </div>
          <h2 className="text-xl font-semibold dark:text-white">Добавить друга</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <div
                className="flex items-center space-x-3 bg-gray-50 dark:bg-neutral-700/30 rounded-xl px-4 py-3 border dark:border-transparent">
              <Search className="w-5 h-5 text-gray-400"/>
              <input
                  value={friendName}
                  onChange={(e) => {
                    setFriendName(e.target.value);
                    fetchSuggestions(e.target.value);
                  }}
                  placeholder="Поиск по имени или email"
                  className="w-full bg-transparent focus:outline-none dark:text-white placeholder-gray-400"
              />
            </div>

            <AnimatePresence>
              {suggestions.length > 0 && (
                  <SuggestionList
                      suggestions={suggestions}
                      onSelect={handleSelect}
                  />
              )}
            </AnimatePresence>
          </div>

          <button
              type="submit"
              disabled={isLoading}
              className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-r from-violet-600 to-blue-500 text-white rounded-xl font-medium hover:shadow-lg transition-all disabled:opacity-50"
          >
            {isLoading ? (
                <>
                  <div className="w-4 h-4 border-2 border-white rounded-full animate-spin"/>
                  Отправка...
                </>
            ) : (
                <>
                  <UserPlus className="w-5 h-5"/>
                  Отправить заявку
                </>
            )}
          </button>
        </form>
      </div>
  );
};

export default AddFriend;