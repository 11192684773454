import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PlayerProvider} from "./context/PlayerContext";
import axios from "axios";

axios.interceptors.response.use(
    response => response,
    error => {
        const currentPath = window.location.pathname;

        // Список путей, где не нужно перенаправление
        const excludedPaths = ['/login', '/register', '/banned'];

        if (excludedPaths.includes(currentPath)) {
            return Promise.reject(error);
        }

        if (error.response?.status === 403 && error.response?.data?.error === 'account_banned') {
            window.location.href = '/banned';
        } else if (error.response?.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PlayerProvider>
        <App />
    </PlayerProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
