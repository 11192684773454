import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackLink from "../BackLink";

function ServiceCard({ title, description, icon, link, extraContent }) {
    return (
        <Link to={link} className="group">
            <div className="bg-white dark:bg-neutral-800 rounded-xl shadow-lg min-h-[300px] p-6 transform transition duration-300">
                {icon && (
                    <img
                        src={icon}
                        alt={`${title} icon`}
                        className="w-16 h-16 mx-auto mb-6 opacity-90 group-hover:opacity-100 transition-opacity duration-200"
                    />
                )}
                <h2 className="text-xl font-bold text-gray-800 dark:text-gray-200 mb-2 group-hover:text-purple-600 dark:group-hover:text-purple-400 transition-colors duration-200">
                    {title}
                </h2>
                <p className="text-gray-600 dark:text-gray-400 text-base mb-4">
                    {description}
                </p>
                {extraContent}
                <span className="inline-block mt-4 text-purple-600 dark:text-purple-400 font-semibold group-hover:underline">
                    Подробнее <i className="fas fa-chevron-right ml-2"></i>
                </span>
            </div>
        </Link>
    );
}

export default function Services() {
    const [weather, setWeather] = useState(null);
    const [isWeatherLoading, setIsWeatherLoading] = useState(true);
    const [exchangeRates, setExchangeRates] = useState(null);
    const [isRatesLoading, setIsRatesLoading] = useState(true);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchWeather(latitude, longitude);
                },
                () => setWeather({ city: "Недоступно", temperature: "-", description: "Ошибка геолокации" })
            );
        }
        fetchExchangeRates();
    }, []);

    const fetchWeather = async (lat, lon) => {
        try {
            const apiKey = "f271c589dc595252feddf5cae33f1718";
            const response = await fetch(
                `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&lang=ru&appid=${apiKey}`
            );
            const data = await response.json();
            setWeather({
                city: data.name,
                temperature: Math.round(data.main.temp),
                description: data.weather[0].description,
            });
        } catch {
            setWeather({ city: "Ошибка", temperature: "-", description: "Невозможно получить данные" });
        } finally {
            setIsWeatherLoading(false);
        }
    };

    const fetchExchangeRates = async () => {
        try {
            const response = await fetch("https://api.exchangerate-api.com/v4/latest/RUB");
            const data = await response.json();
            setExchangeRates({
                USD: (1 / data.rates.USD).toFixed(2),
                EUR: (1 / data.rates.EUR).toFixed(2),
            });
        } catch {
            setExchangeRates({ USD: "-", EUR: "-" });
        } finally {
            setIsRatesLoading(false);
        }
    };

    const services = [
        {
            title: "Погода",
            description: "Узнайте текущую погоду в вашем регионе.",
            icon: "https://img.icons8.com/ios-filled/100/6b21a8/sun.png",
            link: "/services/weather",
            extraContent: isWeatherLoading ? (
                <div className="animate-pulse">
                    <div className="h-4 bg-gray-200 dark:bg-neutral-700 rounded w-3/4 mb-2"></div>
                    <div className="h-4 bg-gray-200 dark:bg-neutral-700 rounded w-1/2"></div>
                </div>
            ) : (
                weather && (
                    <div className="text-sm text-gray-700 dark:text-gray-300">
                        <p className="font-semibold text-gray-800 dark:text-gray-200">{weather.city}</p>
                        <p>{weather.temperature}°C, {weather.description}</p>
                    </div>
                )
            ),
        },
        {
            title: "Курсы валют",
            description: "Текущие курсы доллара и евро.",
            icon: "https://img.icons8.com/ios-filled/100/6b21a8/money.png",
            link: "/services/currency",
            extraContent: isRatesLoading ? (
                <div className="animate-pulse">
                    <div className="h-4 bg-gray-200 dark:bg-neutral-700 rounded w-3/4 mb-2"></div>
                    <div className="h-4 bg-gray-200 dark:bg-neutral-700 rounded w-1/2"></div>
                </div>
            ) : (
                exchangeRates && (
                    <div className="text-sm text-gray-700 dark:text-gray-300">
                        <p>1 USD: {exchangeRates.USD} RUB</p>
                        <p>1 EUR: {exchangeRates.EUR} RUB</p>
                    </div>
                )
            ),
        },
        {
            title: "Тренировки",
            description: "Запустите тренировку и улучшите свои навыки.",
            icon: "https://img.icons8.com/ios-filled/100/6b21a8/dumbbell.png",
            link: "/services/training",
            extraContent: null,
        }
    ];

    return (
        <div className="container mx-auto p-4 min-h-screen bg-gray-50 dark:bg-neutral-900 text-gray-900 dark:text-neutral-100 pt-16">
            <BackLink />
            <h1 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-6">
                Сервисы
            </h1>
            <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {services.map((service, index) => (
                    <ServiceCard
                        key={index}
                        title={service.title}
                        description={service.description}
                        icon={service.icon}
                        link={service.link}
                        extraContent={service.extraContent}
                    />
                ))}
            </div>
        </div>
    );
}
