import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import BackLink from "./BackLink";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";

const UploadTrack = () => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState("");
    const [artist, setArtist] = useState("");
    const [error, setError] = useState("");
    const [albumArt, setAlbumArt] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const { width } = useWindowSize();


    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
        toast.success("Файл выбран!");
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "audio/*",
    });

    const handleUpload = async () => {
        if (!file || !title || !artist || !albumArt) {
            setError("Все поля обязательны для заполнения!");
            toast.error("Заполните все поля!");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", title);
        formData.append("artist", artist);
        formData.append("album_art", albumArt);

        setIsUploading(true);
        setUploadProgress(0);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Ошибка загрузки трека");
            }

            toast.success("Трек успешно загружен!");
            setTimeout(() => {
                window.location.href = "/music";
            }, 2000);
        } catch (error) {
            setError(error.message);
            toast.error(`Ошибка: ${error.message}`);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="container mx-auto p-4">
            {width < 768 && <BottomNav />}
            <BackLink />
            <h2 className="text-3xl font-extrabold text-neutral-800 dark:text-white mb-6">Загрузить трек</h2>

            <div className="p-6 container mx-auto max-w-lg bg-white dark:bg-neutral-900 rounded-lg shadow-lg">
                {error && <p className="text-red-500 mb-4">{error}</p>}

                <label className="block text-lg font-bold text-gray-700 dark:text-white mb-2">Название трека</label>
                <input
                    type="text"
                    placeholder="Название трека"
                    onChange={(e) => setTitle(e.target.value)}
                    className="block w-full p-3 mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white"
                />

                <label className="block text-lg font-bold text-gray-700 dark:text-white mb-2">Исполнитель</label>
                <input
                    type="text"
                    placeholder="Имя исполнителя"
                    onChange={(e) => setArtist(e.target.value)}
                    className="block w-full p-3 mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white"
                />

                <label className="block text-lg font-bold text-gray-700 dark:text-white mb-2">Обложка</label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setAlbumArt(e.target.files[0])}
                    className="block w-full text-sm text-gray-700 dark:text-white file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:bg-purple-100 file:text-purple-700 hover:file:bg-purple-200 dark:file:bg-neutral-700 dark:file:text-white transition duration-300 mb-4"
                />

                <label className="block text-lg font-bold text-gray-700 dark:text-white mb-2">Трек</label>
                <div
                    {...getRootProps()}
                    className={`p-6 border-2 border-dashed rounded-lg text-center ${
                        isDragActive ? "border-purple-500 bg-purple-50" : "border-gray-300 bg-gray-50"
                    } dark:bg-neutral-800 dark:border-neutral-600`}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p className="text-purple-500">Отпустите файл для загрузки...</p>
                    ) : (
                        <p className="text-gray-700 dark:text-white">Перетащите сюда аудиофайл или нажмите для выбора</p>
                    )}
                    {file && (
                        <p className="text-gray-700 dark:text-white mt-2">
                            Выбранный файл: <span className="text-purple-500 ml-2">{file.name}</span>
                        </p>
                    )}
                </div>

                {isUploading && (
                    <div className="w-full bg-gray-200 dark:bg-neutral-700 rounded-full h-2 mt-4">
                        <div
                            className="h-2 bg-purple-500 rounded-full transition-all duration-200"
                            style={{ width: `${uploadProgress}%` }}
                        ></div>
                    </div>
                )}

                <button
                    onClick={handleUpload}
                    disabled={isUploading}
                    className={`w-full py-3 mt-4 text-white rounded-lg shadow-md transition-all ${
                        isUploading ? "bg-gray-400 cursor-not-allowed" : "bg-purple-500 hover:bg-purple-400"
                    }`}
                >
                    {isUploading ? "Загрузка..." : "Загрузить"}
                </button>
            </div>
        </div>
    );
};

export default UploadTrack;
