import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import BackLink from "../BackLink";
import { motion, AnimatePresence } from 'framer-motion';
import { FiTrash2 } from 'react-icons/fi';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const CustomModal = ({ message, onConfirm, onCancel, isOpen }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 backdrop-blur-sm "
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <motion.div
                    className="relative bg-white/80 dark:bg-neutral-800/80 rounded-2xl p-6 w-1/4 shadow-xl backdrop-blur-lg"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.4, ease: 'easeInOut' }}
                >
                    <div className="flex justify-center mb-4">
                        <FiTrash2 className="text-3xl text-red-500" />
                    </div>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white text-center">
                        Подтверждение удаления
                    </h3>

                    <div className="border-t border-gray-300 dark:border-neutral-700 my-4"></div>

                    <p className="text-sm text-gray-700 dark:text-gray-300 mb-6 text-center">
                        {message}
                    </p>

                    <div className="flex justify-between">
                        <button
                            onClick={onCancel}
                            className="w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition-colors"
                        >
                            Отмена
                        </button>
                        <button
                            onClick={onConfirm}
                            className="ml-3 w-full px-4 py-2 text-red-500 border border-transparent hover:bg-red-100 dark:hover:bg-red-600/10 rounded-lg transition-all duration-300 ease-in-out"
                        >
                            Удалить
                        </button>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

export default function DeleteAccount() {
    const { logout, isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDeleteAccount = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            toast.error('Не удалось найти токен. Пожалуйста, войдите снова.');
            return;
        }

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/delete-account`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            toast.success('Аккаунт успешно удален');
            logout();
            navigate('/');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Неавторизованный доступ! Пожалуйста, войдите в систему.');
            } else {
                toast.error('Произошла ошибка при удалении аккаунта!');
            }
            console.error('Ошибка при удалении аккаунта:', error);
        }
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="container mx-auto p-4 h-screen flex flex-col pt-16">
            <BackLink />
            {isLoggedIn ? (
                <>
                    <h1 className="text-3xl font-extrabold mb-4 dark:text-white">Удаление аккаунта</h1>
                    <p className="text-lg text-gray-800 mb-4 dark:text-white">
                        Вы уверены, что хотите удалить свой аккаунт? Это действие необратимо.
                    </p>
                    <div className="flex items-center gap-4 mb-6">
                        <AiOutlineExclamationCircle className="text-2xl text-yellow-500" />
                        <p className="text-gray-600 dark:text-gray-400 text-sm">
                            Убедитесь, что вы сохранили все важные данные перед удалением.
                        </p>
                    </div>
                    <button
                        onClick={openModal}
                        className="w-fit bg-neutral-50 border-2 border-red-600 text-red-600 px-4 py-2 rounded-full hover:bg-red-600 hover:text-neutral-50 transition duration-300 dark:bg-neutral-800 dark:border-red-500 dark:text-red-500 dark:hover:bg-red-500 dark:hover:text-white"
                    >
                        Удалить аккаунт
                    </button>
                    {isModalOpen && (
                        <CustomModal
                            isOpen={isModalOpen}
                            message="Вы уверены, что хотите удалить свой аккаунт? Это действие необратимо."
                            onConfirm={() => {
                                closeModal();
                                handleDeleteAccount();
                            }}
                            onCancel={closeModal}
                        />
                    )}
                </>
            ) : (
                <div className="container mx-auto p-4 ">
                    <h1 className="text-3xl font-extrabold mb-4 mt-4">Удаление аккаунта</h1>
                    <p className="text-lg text-gray-800 mb-4">Войдите в свой аккаунт, чтобы удалить его.</p>
                </div>
            )}
        </div>
    );
}
