import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { Image, Upload, Users, Lock, ArrowLeft } from 'lucide-react';
import BackLink from "./BackLink";

const CreateGroup = () => {
    const [groupData, setGroupData] = useState({
        name: '',
        description: '',
        privacy: 'public',
        avatar: null,
        cover: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [avatarPreview, setAvatarPreview] = useState(null);
    const [coverPreview, setCoverPreview] = useState(null);

    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
    const ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'image/gif'];

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        // Проверка файлов (аватар и обложка)
        if (files) {
            const file = files[0];
            if (file.size > MAX_FILE_SIZE) {
                setError(`${name === 'avatar' ? 'Аватар' : 'Обложка'} превышает допустимый размер 2MB.`);
                toast.error(`${name === 'avatar' ? 'Аватар' : 'Обложка'} превышает допустимый размер 2MB.`);
                return;
            }
            if (!ALLOWED_FORMATS.includes(file.type)) {
                setError(`${name === 'avatar' ? 'Аватар' : 'Обложка'} имеет недопустимый формат.`);
                toast.error(`${name === 'avatar' ? 'Аватар' : 'Обложка'} имеет недопустимый формат.`);
                return;
            }
        }

        setGroupData((prevData) => ({
            ...prevData,
            [name]: files ? files[0] : value,
        }));
        setError(null); // Сброс ошибки при корректном вводе
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                setError(`${name === 'avatar' ? 'Аватар' : 'Обложка'} превышает допустимый размер 2MB.`);
                toast.error(`${name === 'avatar' ? 'Аватар' : 'Обложка'} превышает допустимый размер 2MB.`);
                return;
            }
            if (!ALLOWED_FORMATS.includes(file.type)) {
                setError(`${name === 'avatar' ? 'Аватар' : 'Обложка'} имеет недопустимый формат.`);
                toast.error(`${name === 'avatar' ? 'Аватар' : 'Обложка'} имеет недопустимый формат.`);
                return;
            }

            const previewUrl = URL.createObjectURL(file);

            if (name === 'avatar') {
                setAvatarPreview(previewUrl);
            } else {
                setCoverPreview(previewUrl);
            }

            setGroupData(prev => ({
                ...prev,
                [name]: file
            }));
        }
    };

    const validateForm = () => {
        const { name, description } = groupData;
        if (!name || !description) {
            setError('Название и описание обязательны');
            toast.error('Название и описание обязательны.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formData = new FormData();
        Object.keys(groupData).forEach((key) => {
            formData.append(key, groupData[key]);
        });

        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/groups`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            toast.success('Группа успешно создана!');
            navigate('/groups');
        } catch (error) {
            setError('Ошибка при создании группы. Попробуйте снова.');
            toast.error('Ошибка при создании группы.');
            console.error('Error creating group:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="min-h-screen bg-gradient-to-br from-gray-50 to-violet-50 dark:from-neutral-900 dark:to-neutral-800"
        >
            <div className="container mx-auto px-6 py-16">
                <BackLink />
                <div className="bg-white dark:bg-neutral-800 rounded-2xl shadow-2xl p-8 mt-4">
                    <h1 className="text-3xl font-extrabold mb-8 dark:text-white">Создание группы</h1>
                    <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        <div className="space-y-6">
                            <div className="group relative">
                                <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                                    Аватар группы
                                </label>
                                <div className="relative h-48 w-full rounded-2xl border-2 border-dashed border-gray-300 dark:border-neutral-600 hover:border-violet-500 transition-colors flex items-center justify-center overflow-hidden">
                                    <input
                                        type="file"
                                        name="avatar"
                                        onChange={handleFileChange}
                                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                        accept="image/*"
                                    />
                                    {avatarPreview ? (
                                        <img
                                            src={avatarPreview}
                                            alt="Аватар"
                                            className="w-full h-full object-cover"
                                        />
                                    ) : (
                                        <div className="text-center p-4">
                                            <Upload className="w-8 h-8 mx-auto text-gray-400 mb-2" />
                                            <p className="text-gray-500 dark:text-gray-400 text-sm">
                                                Перетащите фото или нажмите для загрузки
                                            </p>
                                            <p className="text-xs text-gray-400 mt-1">
                                                Рекомендуемый размер: 500×500px
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="group relative">
                                <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                                    Обложка группы
                                </label>
                                <div className="relative h-48 w-full rounded-2xl border-2 border-dashed border-gray-300 dark:border-neutral-600 hover:border-violet-500 transition-colors flex items-center justify-center overflow-hidden">
                                    <input
                                        type="file"
                                        name="cover"
                                        onChange={handleFileChange}
                                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                        accept="image/*"
                                    />
                                    {coverPreview ? (
                                        <img
                                            src={coverPreview}
                                            alt="Обложка"
                                            className="w-full h-full object-cover"
                                        />
                                    ) : (
                                        <div className="text-center p-4">
                                            <Image className="w-8 h-8 mx-auto text-gray-400 mb-2" />
                                            <p className="text-gray-500 dark:text-gray-400 text-sm">
                                                Перетащите обложку или нажмите для загрузки
                                            </p>
                                            <p className="text-xs text-gray-400 mt-1">
                                                Макс. размер: 2MB • JPG, PNG, GIF
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div>
                                <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                                    Название группы
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={groupData.name}
                                    onChange={handleChange}
                                    className="w-full px-4 py-3 rounded-xl bg-gray-50 dark:bg-neutral-700 border border-gray-200 dark:border-neutral-600 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all"
                                    placeholder="Введите название группы"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                                    Описание группы
                                </label>
                                <textarea
                                    name="description"
                                    value={groupData.description}
                                    onChange={handleChange}
                                    rows="4"
                                    className="w-full px-4 py-3 rounded-xl bg-gray-50 dark:bg-neutral-700 border border-gray-200 dark:border-neutral-600 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all"
                                    placeholder="Опишите цель и тематику вашей группы"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-3 text-gray-700 dark:text-gray-300">
                                    Приватность группы
                                </label>
                                <div className="grid grid-cols-2 gap-3">
                                    <label className={`relative cursor-pointer p-4 rounded-xl border-2 ${
                                        groupData.privacy === 'public'
                                            ? 'border-violet-500 bg-violet-50 dark:bg-neutral-700'
                                            : 'border-gray-200 dark:border-neutral-600 hover:border-gray-300'
                                    }`}>
                                        <input
                                            type="radio"
                                            name="privacy"
                                            value="public"
                                            checked={groupData.privacy === 'public'}
                                            onChange={handleChange}
                                            className="absolute opacity-0"
                                        />
                                        <div className="flex items-center space-x-3">
                                            <Users className="w-5 h-5 text-violet-600 dark:text-violet-400" />
                                            <div>
                                                <div className="font-medium">Публичная</div>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Видна всем пользователям
                                                </p>
                                            </div>
                                        </div>
                                    </label>

                                    <label className={`relative cursor-pointer p-4 rounded-xl border-2 ${
                                        groupData.privacy === 'private'
                                            ? 'border-violet-500 bg-violet-50 dark:bg-neutral-700'
                                            : 'border-gray-200 dark:border-neutral-600 hover:border-gray-300'
                                    }`}>
                                        <input
                                            type="radio"
                                            name="privacy"
                                            value="private"
                                            checked={groupData.privacy === 'private'}
                                            onChange={handleChange}
                                            className="absolute opacity-0"
                                        />
                                        <div className="flex items-center space-x-3">
                                            <Lock className="w-5 h-5 text-violet-600 dark:text-violet-400" />
                                            <div>
                                                <div className="font-medium">Приватная</div>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Только по приглашению
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                type="submit"
                                disabled={isLoading}
                                className="w-full py-4 px-6 bg-gradient-to-r from-violet-600 to-blue-500 text-white rounded-xl font-semibold shadow-lg hover:shadow-xl transition-all disabled:opacity-50"
                            >
                                {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <div className="w-4 h-4 border-2 border-white rounded-full animate-spin" />
                                        <span>Создание группы...</span>
                                    </div>
                                ) : (
                                    'Создать группу'
                                )}
                            </motion.button>
                        </div>
                    </form>
                </div>
            </div>
        </motion.div>
    );
};

export default CreateGroup;
