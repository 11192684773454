import React from 'react';
import AdminAside from './AdminAside';

const AdminLayout = ({ children }) => {
    return (
        <div className="container mx-auto px-4 min-h-[calc(100vh-100px)] bg-neutral-50 dark:bg-neutral-900 transition-colors duration-300 flex pt-8">
            <AdminAside />
            <div className="flex-1 p-4 sm:p-6 lg:p-8">
                <div className="max-w-7xl mx-auto bg-white dark:bg-neutral-800 shadow-xl rounded-xl p-6 transition-all duration-300 overflow-y-auto h-[calc(100vh-100px)]">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;