import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import BackLink from "./BackLink";
import ProfilePosts from "./profile/ProfilePosts";
import {ClipLoader} from "react-spinners";

function UserProfile() {
  const [user, setUser] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const { userId } = useParams();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке профиля пользователя:', error);
      }
    };

    if (isLoggedIn) {
      fetchUserProfile();
    }
  }, [isLoggedIn, userId]);

  if (!user) {
    return <div className="flex items-center justify-center min-h-screen pt-16">
              <ClipLoader color="#6B46C1" size={75}/>
           </div>
  }

  return (
      <div className="container mx-auto p-4 h-screen flex flex-col lg:flex-row items-start gap-8 pt-16">
        <BackLink/>

        {/* Левая часть: информация о пользователе */}
        <div className="bg-gray-50 dark:bg-neutral-900 p-6 rounded-2xl shadow-md flex flex-col items-center w-full lg:w-1/3 mt-8">
          <img
              src={user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}` : `https://ui-avatars.com/api/?name=${user.name}&background=random`}
              alt={user.name}
              className="w-32 h-32 rounded-full object-cover shadow-md mb-4"
          />
          <div className="text-center">
            <h2 className="text-xl font-bold dark:text-white mb-2">{user.name}</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">{user.status || 'Описание отсутствует'}</p>
            <p className="text-gray-600 dark:text-gray-300 mt-2">{user.email}</p>
          </div>

          {/* Галерея или фото */}
          <div className="grid grid-cols-3 gap-2 mt-6">
            {user.photos && user.photos.map((photo, index) => (
                <img
                    key={index}
                    src={`${process.env.REACT_APP_BASE_URL}/storage/${photo}`}
                    alt={`Photo ${index + 1}`}
                    className="w-full h-24 object-cover rounded-lg"
                />
            ))}
          </div>
        </div>

        {/* Правая часть: посты */}
        <div className="flex-1 mt-8 w-full">
          <ProfilePosts userId={userId} />
        </div>
      </div>
  );
}

export default UserProfile;
