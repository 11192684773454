import React, {useEffect, useState} from 'react';
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Home from './components/Home';
import Header from './components/Header';
import Profile from './components/Profile';
import { AuthProvider } from './context/AuthContext';
import { Toaster } from 'react-hot-toast';
import ChatList from './components/ChatList';
import Chat from './components/Chat';
import AddFriend from './components/friends/AddFriend';
import CreateChat from './components/CreateChat';
import FriendList from './components/friends/FriendList';
import Settings from './components/Settings';
import ChangeData from './components/settings/ChangeData';
import DeleteAccount from './components/settings/DeleteAccount';
import Report from './components/settings/Report';
import Support from './components/settings/Support';
import UserProfile from './components/UserProfile';
import Post from "./components/Post";
import PostList from "./components/PostList";
import CreatePost from "./components/CreatePost";
import Search from "./components/Search";
import PostList2 from "./components/PostList2";
import Notifications from "./components/Notifications";
import CreateGroup from "./components/CreateGroup";
import GroupList from "./components/GroupList";
import GroupDetails from "./components/GroupDetails";
import Echo from 'laravel-echo';
import MobileMenu from "./components/MobileMenu";
import Services from "./components/services/Services";
import WeatherDetails from "./components/services/WetherDetails";
import CurrencyDetails from "./components/services/CurrencyDetails";
import QrLoginDesktop from "./components/QrLoginDesktop";
import QrLoginMobile from "./components/QrLoginMobile";
import FriendListForProfile from "./components/profile/FriendListForProfile";
import TrackList from "./components/TrackList";
import UploadTrack from "./components/UploadTrack";
import {PlayerProvider} from "./context/PlayerContext";
import MusicPlayer from "./components/MusicPlayer";
import AdminPanel from "./components/AdminPanel";
import BannedPage from "./components/BannedPage";
import AdminMusic from "./components/AdminMusic";
import UploadAlbum from "./components/UploadAlbum";
import AlbumDetails from "./components/AlbumDetails";
import Valentine from "./components/Valentine";

function App() {
  const [posts, setPosts] = useState([]);
  const [tracks, setTracks] = useState([]); // Добавляем состояние для треков

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        setTracks(data);
      } catch (err) {
        console.error("Ошибка загрузки треков:", err);
      }
    };

    fetchTracks();
  }, []);

  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: true
    });
  }



  return (
    <AuthProvider>
      <PlayerProvider tracks={tracks}>
    <Router>
      <div className='h-min-screen h-max-screen dark:bg-neutral-900'>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/qr-login" element={<QrLoginMobile />} />
          <Route path="/qr-generate" element={<QrLoginDesktop />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/chats" element={<ChatList />} />
          <Route path="/chats/:chatId" element={<Chat />} />
          <Route path="/add-friend" element={<AddFriend />} />
          <Route path="/create-chat" element={<CreateChat />} />
          <Route path='/friends' element={<FriendList />} />
          <Route path="/users/:userId/friends" element={<FriendListForProfile />} />
          <Route path='messages' element={<ChatList />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/settings/change-password' element={<ChangeData />} />
          <Route path='/settings/delete-account' element={<DeleteAccount />} />
          <Route path='/settings/report-issue' element={<Report />} />
          <Route path='/settings/contact-support' element={<Support />} />
          <Route path='/profile/:userId' element={<UserProfile />} />
          <Route path='/posts' element={<PostList setPosts={setPosts} posts={posts} />} />
          <Route path='/posts/all' element={<PostList2 setPosts={setPosts} posts={posts} />} />
          <Route path='/posts/:postId' element={<Post />} />
          <Route path='/create-post' element={<CreatePost />} />
          <Route path="/groups" element={<GroupList />} />
          <Route path="/create-group" element={<CreateGroup />} />
          <Route path="/group/:id" element={<GroupDetails />} />
          <Route path='/search' element={<Search />} />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/menu' element={<MobileMenu />} />
          <Route path='/services' element={<Services />} />
          <Route path='/services/weather' element={<WeatherDetails />} />
          <Route path='/services/currency' element={<CurrencyDetails />} />
          <Route path='/music' element={<TrackList />} />
          <Route path='/upload-track' element={<UploadTrack />} />
          <Route path="/albums/:id" element={<AlbumDetails />} />
          <Route path="/upload-album" element={<UploadAlbum />} />
          <Route path='/admin' element={<AdminPanel />} />
          <Route path="/admin/music" element={<AdminMusic />} />
          <Route path="/banned" element={<BannedPage />} />
          <Route path="/люблю_тебя_полиночка" element={<Valentine />} />
        </Routes>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </Router>
        <MusicPlayer />
      </PlayerProvider>
    </AuthProvider>
  );
}

export default App;
