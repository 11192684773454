import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { usePlayer } from "../context/PlayerContext";
import { Volume2, VolumeX, Volume1, Volume } from "lucide-react";

const VolumeControl = () => {
    const { volume, setAudioVolume } = usePlayer();
    const [isMuted, setIsMuted] = useState(volume === 0);
    const [isHovered, setIsHovered] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const containerRef = useRef(null);
    const animationFrame = useRef(null);

    const getVolumeIcon = () => {
        if (isMuted || volume === 0) return <VolumeX size={20} />;
        if (volume < 0.33) return <Volume size={20} />;
        if (volume < 0.66) return <Volume1 size={20} />;
        return <Volume2 size={20} />;
    };

    const handleMuteToggle = () => {
        const newVolume = isMuted ? 0.5 : 0;
        setIsMuted(!isMuted);
        setAudioVolume(newVolume);
    };

    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        setIsMuted(newVolume === 0);
        setAudioVolume(newVolume);
    };

    const handleContainerClick = (e) => {
        if (!containerRef.current) return;
        const rect = containerRef.current.getBoundingClientRect();
        const newVolume = Math.min(Math.max((e.clientX - rect.left) / rect.width, 0), 1);
        setAudioVolume(newVolume);
    };

    const smoothVolumeChange = (delta) => {
        if (animationFrame.current) cancelAnimationFrame(animationFrame.current);

        const newVolume = Math.min(Math.max(volume + delta, 0), 1);
        setAudioVolume(newVolume);
        setIsMuted(newVolume === 0);

        animationFrame.current = requestAnimationFrame(() => {
            smoothVolumeChange(delta);
        });
    };

    const handleWheel = (e) => {
        e.preventDefault();
        const delta = e.deltaY > 0 ? -0.05 : 0.05;
        setAudioVolume(Math.min(Math.max(volume + delta, 0), 1));
    };

    return (
        <div
            className="relative flex items-center gap-2 group"
            ref={containerRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onWheel={handleWheel}
        >
            <motion.button
                onClick={handleMuteToggle}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="p-4 rounded-full bg-white/10 dark:bg-neutral-700/30 dark:text-white backdrop-blur-sm shadow-glass hover:bg-white/20 transition-all"
            >
                {getVolumeIcon()}
            </motion.button>

            <AnimatePresence>
                {(isHovered || isDragging) && (
                    <motion.div
                        className="absolute left-full ml-2 flex items-center w-32 h-8 bg-white/10 dark:bg-neutral-700/30 backdrop-blur-sm rounded-full shadow-glass px-3"
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -10 }}
                    >
                        <input
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={isMuted ? 0 : volume}
                            onChange={handleVolumeChange}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            className="w-full h-1 bg-gray-300/50 dark:bg-neutral-600/50 rounded-full cursor-pointer appearance-none accent-purple-500 hover:accent-purple-400 transition-colors"
                            style={{
                                background: `linear-gradient(to right, rgba(139, 92, 246, 0.8) ${volume * 100}%, transparent ${volume * 100}%)`
                            }}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default VolumeControl;