import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { LockClosedIcon, ClockIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

const BannedPage = () => {
    const location = useLocation();
    const [banInfo, setBanInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBanData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/auth/banned', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                // Проверяем статус бана
                if (!response.data.is_banned) {
                    navigate('/'); // Перенаправляем если бан снят
                    return;
                }

                setBanInfo(response.data);
            } catch (err) {
                setError(err.response?.data?.message || 'Ошибка загрузки данных');
            } finally {
                setLoading(false);
            }
        };

        fetchBanData();
    }, [navigate]);

    if (banInfo && !banInfo.is_banned) {
        navigate('/', { replace: true });
        return null; // Прерываем рендеринг
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'Перманентная блокировка';
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-white dark:bg-neutral-900">
                <div className="animate-pulse text-lg dark:text-neutral-300">Загрузка информации о блокировке...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-white dark:bg-neutral-900">
                <div className="text-red-500 dark:text-red-400 text-lg">{error}</div>
            </div>
        );
    }

    const {
        message = 'Ваш аккаунт заблокирован',
        reason = 'Нарушение правил сообщества',
        banned_until = null,
        appeal_status,
        admin_comment
    } = banInfo || {};

    return (
        <div className="min-h-screen flex items-center justify-center p-4 bg-white dark:bg-neutral-900 transition-colors duration-300">
            <div className="w-full max-w-2xl bg-white rounded-2xl shadow-xl p-4
                dark:bg-neutral-800 dark:shadow-2xl transition-all duration-300 hover:shadow-2xl">

                <div className="flex flex-col items-center space-y-4">
                    <div className="bg-red-100 p-4 rounded-full dark:bg-red-900/20">
                        <LockClosedIcon className="h-12 w-12 text-red-600 dark:text-red-400" />
                    </div>

                    <h1 className="text-3xl font-bold text-neutral-900 dark:text-neutral-100">
                        Доступ ограничен
                    </h1>

                    <div className="space-y-4 w-full">
                        <div className="bg-red-50 p-4 rounded-lg border border-red-100 dark:bg-red-900/20 dark:border-red-800">
                            <div className="flex items-center space-x-2">
                                <InformationCircleIcon className="h-5 w-5 text-red-600 dark:text-red-400" />
                                <p className="text-red-600 font-medium dark:text-red-300">
                                    {message}
                                </p>
                            </div>
                        </div>

                        <div className="bg-neutral-50 p-4 rounded-lg dark:bg-neutral-700/30">
                            <div className="space-y-2">
                                <div className="flex items-center space-x-2">
                                    <ClockIcon className="h-5 w-5 text-neutral-600 dark:text-neutral-400" />
                                    <span className="font-medium text-neutral-700 dark:text-neutral-300">
                                        Срок блокировки:
                                    </span>
                                    <span className="text-neutral-700 dark:text-neutral-300">
                                        {formatDate(banned_until)}
                                    </span>
                                </div>

                                <div className="flex items-start space-x-2">
                                    <InformationCircleIcon className="h-5 w-5 text-neutral-600 dark:text-neutral-400" />
                                    <div>
                                        <p className="font-medium text-neutral-700 dark:text-neutral-300">Причина:</p>
                                        <p className="text-neutral-700 dark:text-neutral-300">{reason}</p>
                                    </div>
                                </div>

                                {admin_comment && (
                                    <div className="flex items-start space-x-2 pt-2">
                                        <InformationCircleIcon className="h-5 w-5 text-neutral-600 dark:text-neutral-400" />
                                        <div>
                                            <p className="font-medium text-neutral-700 dark:text-neutral-300">
                                                Комментарий администратора:
                                            </p>
                                            <p className="text-neutral-700 dark:text-neutral-300">
                                                {admin_comment}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="bg-blue-50 p-4 rounded-lg border border-blue-100 dark:bg-blue-900/20 dark:border-blue-800">
                            <h3 className="font-medium mb-2 text-blue-800 dark:text-blue-300">
                                Что можно сделать?
                            </h3>
                            <ul className="list-disc list-inside space-y-2 text-blue-700 dark:text-blue-300">
                                <li>Ожидать автоматического снятия блокировки</li>
                                <li>Обратиться в поддержку для выяснения деталей</li>
                                {appeal_status !== 'pending' && (
                                    <li>Подать апелляцию, если считаете блокировку ошибкой</li>
                                )}
                                {appeal_status === 'pending' && (
                                    <li className="text-green-600 dark:text-green-400">Апелляция на рассмотрении</li>
                                )}
                            </ul>
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row gap-4 w-full mt-6">
                        <button
                            onClick={() => window.location.href = '/login'}
                            className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-6 rounded-lg
                                transition-all duration-200 flex-1 flex items-center justify-center space-x-2
                                dark:bg-blue-700 dark:hover:bg-blue-600">
                            <span>Вернуться на страницу входа</span>
                        </button>

                        <a
                            href="mailto:pvova4370@gmail.com"
                            className="bg-neutral-100 hover:bg-neutral-200 text-neutral-700 font-medium py-3 px-6 rounded-lg
                                transition-all duration-200 flex-1 flex items-center justify-center space-x-2
                                dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-200">
                            <i className="fas fa-envelope h-5 w-5" />
                            <span>Написать в поддержку</span>
                        </a>
                    </div>

                    <p className="text-neutral-500 text-sm mt-6 text-center dark:text-neutral-400">
                        Если вы считаете, что это ошибка, приложите доказательства
                        в письме поддержки. Среднее время ответа — 24 часа.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BannedPage;