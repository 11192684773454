// ProfileInfo Component
import React, { useState } from 'react';
import axios from 'axios';
import FriendListForProfile from './FriendListForProfile';
import OnlineStatus from "../OnlineStatus";
import { motion } from 'framer-motion';
import AvatarGallery from "./AvatarGallery";
import ImageModal from './ImageModal';

function ProfileInfo({ user }) {
    const [description, setDescription] = useState(user.status || '');
    const [isEditing, setIsEditing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isImageModalOpen, setImageModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState(null);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setDescription(user.status || '');
    };

    const handleSaveClick = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/profile/update-status`,
                { status: description },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            user.status = response.data.status;
            setIsEditing(false);
        } catch (error) {
            console.error("Ошибка при обновлении описания профиля", error);
        }
    };

    const openImageModal = (src) => {
        setModalImageSrc(src);
        setImageModalOpen(true);
    };

    const closeImageModal = () => setImageModalOpen(false);

    return (
        <div className="text-center space-y-6 m-0 p-0">
            <motion.p
                className="text-2xl font-semibold text-gray-900 dark:text-gray-100"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                {user.name}
            </motion.p>
            {isEditing ? (
                <motion.div
                    className="space-y-3"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                >
        <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 text-gray-800 dark:text-gray-200 bg-transparent border-b-2 border-purple-200 dark:border-neutral-600 focus:border-purple-500 focus:outline-none resize-none"
            rows="3"
            placeholder="Добавьте описание..."
        />
                    <div className="flex gap-2 text-sm">
                        <button
                            onClick={handleSaveClick}
                            className="px-4 py-1.5 text-white bg-purple-600 hover:bg-purple-700 rounded-md transition-colors"
                        >
                            Сохранить
                        </button>
                        <button
                            onClick={handleCancelClick}
                            className="px-4 py-1.5 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-md transition-colors"
                        >
                            Отмена
                        </button>
                    </div>
                </motion.div>
            ) : (
                <div
                    className="relative group"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <div className="text-gray-700 dark:text-gray-300 text-sm">
                        {user.status || 'Нажмите редактировать чтобы добавить описание'}

                        {isHovered && (
                            <button
                                onClick={handleEditClick}
                                className="ml-2 text-purple-600 dark:text-purple-400 hover:text-purple-700 dark:hover:text-purple-500 transition-colors"
                            >
                                <i className="fas fa-edit text-sm"></i>
                            </button>
                        )}
                    </div>
                </div>
            )}

            <AvatarGallery avatars={user.avatars} onImageClick={openImageModal} />

            {isImageModalOpen && (
                <ImageModal src={modalImageSrc} alt="Аватар" onClose={closeImageModal} />
            )}

            <motion.p
                className="text-gray-800 dark:text-gray-400 text-sm flex items-center justify-center gap-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <i className="fas fa-envelope"></i>{user.email}
            </motion.p>

            <FriendListForProfile />
        </div>
    );
}

export default ProfileInfo;
