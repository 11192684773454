import React, { useState, useEffect, useRef } from 'react';
import { Heart, Sparkles } from 'lucide-react';
import clickSoundFile from '../assets/click.mp3'; // Импорт файла
import gameOverSoundFile from '../assets/click.mp3'; // Импорт файла

const ValentineGame = () => {
    const [score, setScore] = useState(0);
    const [timeLeft, setTimeLeft] = useState(30);
    const [gameOver, setGameOver] = useState(false);
    const [hearts, setHearts] = useState([]);
    const [effects, setEffects] = useState([]);
    const containerRef = useRef(null);
    const clickSound = useRef(new Audio(clickSoundFile)); // Использование импортированного файла
    const gameOverSound = useRef(new Audio(gameOverSoundFile)); // Использование импортированного файла

    const cutePhrases = [
        'Люблю!',
        'Моя милашка!',
        'Сердечко!',
        'Ты – моя радость!',
        'Обожаю!',
        'Солнышко!',
        'Я тебя люблю!',
        'Моя любимая!',
        'Любимка',
        'Чмок😘',
        'Котеночек😺',
        'Мой зайчик',
        'Самая лучшая девушка!',
        'Самая самая',
    ];

    // Функция для воспроизведения звука с обработкой ошибок
    const playSound = (soundRef) => {
        try {
            soundRef.current.play().catch((error) => {
                console.error('Ошибка воспроизведения звука:', error);
            });
        } catch (error) {
            console.error('Ошибка при попытке воспроизвести звук:', error);
        }
    };

    // Генерация падающих сердечек
    useEffect(() => {
        if (timeLeft > 0 && !gameOver) {
            const interval = setInterval(() => {
                setHearts((prev) => [
                    ...prev,
                    {
                        id: Date.now() + Math.random(),
                        x: Math.random() * (window.innerWidth - 50),
                        speed: 2 + Math.random() * 3,
                    },
                ]);
            }, 700);
            return () => clearInterval(interval);
        }
    }, [timeLeft, gameOver]);

    // Таймер игры
    useEffect(() => {
        if (timeLeft > 0 && !gameOver) {
            const timer = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else if (timeLeft === 0 && !gameOver) {
            setGameOver(true);
            playSound(gameOverSound); // Воспроизведение звука завершения игры
        }
    }, [timeLeft, gameOver]);

    // Обработка клика по сердечку
    const handleHeartClick = (id, e) => {
        setScore((prev) => prev + 1);
        setHearts((prev) => prev.filter((item) => item.id !== id));
        playSound(clickSound); // Воспроизведение звука клика

        const containerRect = containerRef.current.getBoundingClientRect();
        const clickX = e.clientX - containerRect.left;
        const clickY = e.clientY - containerRect.top;

        const safeX = Math.max(20, Math.min(clickX, containerRect.width - 100));
        const safeY = Math.max(20, Math.min(clickY, containerRect.height - 40));

        const message = cutePhrases[Math.floor(Math.random() * cutePhrases.length)];

        const effectId = Date.now() + Math.random();
        const newEffect = { id: effectId, x: safeX, y: safeY, message };

        setEffects((prev) => [...prev, newEffect]);

        setTimeout(() => {
            setEffects((prev) => prev.filter((eff) => eff.id !== effectId));
        }, 1200);
    };

    // Сброс игры
    const resetGame = () => {
        setScore(0);
        setTimeLeft(30);
        setGameOver(false);
        setHearts([]);
        setEffects([]);
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-pink-100 to-rose-200 flex flex-col items-center justify-center p-4 font-pacifico overflow-hidden relative">
            {/* Анимация мерцания фона */}
            <div className="w-full h-full bg-gradient-to-br from-pink-100 to-rose-200"></div>

            {/* Заголовок с анимацией */}
            <h1 className="text-3xl text-transparent bg-clip-text bg-gradient-to-r from-pink-600 to-rose-700 mb-4">
                Лови сердечки!
            </h1>

            {/* Игровое поле */}
            <div
                ref={containerRef}
                className="relative w-full max-w-md h-[60vh] bg-white/90 backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden"
            >
                {/* Падающие сердечки */}
                {hearts.map((heart) => (
                    <div
                        key={heart.id}
                        className="absolute text-rose-500 animate-fall cursor-pointer"
                        style={{
                            left: `${heart.x}px`,
                            animationDuration: `${heart.speed}s`,
                        }}
                        onClick={(e) => handleHeartClick(heart.id, e)}
                    >
                        <Heart className="w-10 h-10 animate-heartbeat" />
                    </div>
                ))}

                {/* Эффекты при клике */}
                {effects.map((effect) => (
                    <div
                        key={effect.id}
                        style={{ left: effect.x, top: effect.y }}
                        className="absolute flex items-center gap-1 effect"
                    >
                        <Sparkles className="w-5 h-5 text-yellow-500 animate-sparkle" />
                        <span className="text-md text-rose-600">{effect.message}</span>
                    </div>
                ))}

                {/* Счётчик и таймер */}
                <div className="absolute top-4 left-4 bg-rose-100 px-4 py-2 rounded-full text-rose-800 flex items-center gap-2">
                    <Heart className="w-4 h-4" />
                    <span>{score}</span>
                </div>
                <div className="absolute top-4 right-4 bg-rose-100 px-4 py-2 rounded-full text-rose-800">
                    ⏳ {timeLeft} сек
                </div>
            </div>

            {/* Завершение игры с поздравлением */}
            {gameOver && (
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-60 z-50 animate-fade-in">
                    <div className="bg-white flex flex-col p-6 rounded-xl shadow-xl text-center max-w-sm mx-4 animate-scale-in">
                        <h2 className="text-3xl font-bold mb-4 text-pink-600">
                            С праздником, Полиночка!
                        </h2>
                        <p className="text-lg mb-2">
                            Я тебя очень сильно люблю!
                        </p>
                        <p className="text-md text-rose-700 mb-6">
                            Надеюсь, что мы будем вместе столько лет, сколько ты собрала сердечек, помноженных на два!
                        </p>
                        <span className="flex items-center gap-2 mb-4 mx-auto text-3xl font-bold text-pink-600">
                            ❤️{score}
                        </span>
                        <button
                            onClick={resetGame}
                            className="px-6 py-2 bg-pink-500 text-white rounded-full hover:bg-pink-600 transition-colors"
                        >
                            Играть снова
                        </button>
                    </div>
                </div>
            )}

            {/* Глобальные стили */}
            <style jsx global>{`
                @keyframes fall {
                    0% {
                        transform: translateY(-100px) rotate(0deg);
                        opacity: 0;
                    }
                    30% {
                        opacity: 1;
                    }
                    100% {
                        transform: translateY(calc(60vh - 50px)) rotate(360deg);
                        opacity: 0.5;
                    }
                }
                @keyframes pop {
                    0% {
                        opacity: 1;
                        transform: translateY(0);
                    }
                    100% {
                        opacity: 0;
                        transform: translateY(-50px);
                    }
                }
                @keyframes twinkle {
                    0%, 100% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0.8;
                    }
                }
                @keyframes bounce-in {
                    0% {
                        transform: scale(0.5);
                        opacity: 0;
                    }
                    70% {
                        transform: scale(1.1);
                        opacity: 1;
                    }
                    100% {
                        transform: scale(1);
                    }
                }
                @keyframes heartbeat {
                    0%, 100% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.2);
                    }
                }
                @keyframes sparkle {
                    0% {
                        transform: scale(1);
                        opacity: 1;
                    }
                    50% {
                        transform: scale(1.5);
                        opacity: 0.5;
                    }
                    100% {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
                @keyframes fade-in {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
                @keyframes scale-in {
                    0% {
                        transform: scale(0.8);
                        opacity: 0;
                    }
                    100% {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
                .animate-fall {
                    animation: fall linear forwards;
                }
                .effect {
                    animation: pop 1.2s ease-out forwards;
                    pointer-events: none;
                }
                .animate-twinkle {
                    animation: twinkle 2s infinite;
                }
                .animate-bounce-in {
                    animation: bounce-in 1s ease-out;
                }
                .animate-heartbeat {
                    animation: heartbeat 1s infinite;
                }
                .animate-sparkle {
                    animation: sparkle 0.5s ease-out;
                }
                .animate-fade-in {
                    animation: fade-in 0.5s ease-out;
                }
                .animate-scale-in {
                    animation: scale-in 0.5s ease-out;
                }
                .font-pacifico {
                    font-family: 'Pacifico', cursive;
                }
            `}</style>
        </div>
    );
};

export default ValentineGame;