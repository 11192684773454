import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import Pusher from "pusher-js";
import BottomNav from "./BottomNav";
import useWindowSize from "../hooks/useWindowSize";
import {
    MessageCircle,
    Search,
    Compass,
    Music,
    SlidersHorizontal,
    ClipboardList,
    UserCircle,
    Users2, BellRing,
    Grid2x2Plus
} from "lucide-react";

const NAV_ITEMS = [
    { path: "/profile", icon: UserCircle, label: "Профиль" },
    { path: "/messages", icon: MessageCircle, label: "Сообщения" },
    { path: "/posts/all", icon: ClipboardList, label: "Посты" },
    { path: "/friends", icon: Users2, label: "Друзья" },
    { path: "/notifications", icon: BellRing, label: "Уведомления" },
    { path: "/search", icon: Search, label: "Поиск" },
    { path: "/groups", icon: Compass, label: "Группы" },
    { path: "/music", icon: Music, label: "Музыка" },
    { path: "/services", icon: Grid2x2Plus, label: "Сервисы" },
    { path: "/settings", icon: SlidersHorizontal, label: "Настройки" },
];

function Aside() {
    const { isLoggedIn } = useContext(AuthContext);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const { width } = useWindowSize();

    const fetchUnreadMessages = useCallback(async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                setUnreadMessagesCount(0);
                return;
            }
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/unread_count`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUnreadMessagesCount(response.data?.unread_count || 0);
        } catch {
            setUnreadMessagesCount(0);
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            fetchUnreadMessages().then(r => r);
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, { cluster: "eu" });
            const channel = pusher.subscribe("messages");
            channel.bind("new-message", fetchUnreadMessages);
            const intervalId = setInterval(fetchUnreadMessages, 30000);
            return () => {
                channel.unbind("new-message", fetchUnreadMessages);
                pusher.unsubscribe("messages");
                clearInterval(intervalId);
            };
        }
    }, [isLoggedIn, fetchUnreadMessages]);

    if (width < 768) {
        return <BottomNav unreadMessagesCount={unreadMessagesCount} />;
    }

    return (
        <aside className="w-1/4 p-4 sticky pt-16 top-0 h-[calc(100vh-73px)] overflow-y-auto flex flex-col">
            <ul className="space-y-2">
                {NAV_ITEMS.map(({path, icon: Icon, label}) => (
                    <li key={path} className="group">
                        <Link
                            to={path}
                            className="flex items-center p-2 rounded-lg transition-transform duration-200 hover:scale-105 bg-white dark:bg-neutral-800 group-hover:shadow-md group-hover:bg-gray-50 dark:group-hover:bg-neutral-700"
                        >
                            <Icon className="mr-3 w-6 h-6 text-lg text-gray-600 dark:text-gray-300 group-hover:text-purple-600 dark:group-hover:text-purple-400" />
                            <div className="flex justify-between items-center w-full">
                <span className="font-medium text-gray-800 dark:text-gray-200 group-hover:text-purple-600 dark:group-hover:text-purple-400">
                  {label}
                </span>
                                {path === "/messages" && unreadMessagesCount > 0 && (
                                    <span className="bg-red-500 text-white text-xs w-5 h-5 flex justify-center items-center rounded-full shadow-sm">
                    {unreadMessagesCount}
                  </span>
                                )}
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </aside>
    );
}

export default Aside;
