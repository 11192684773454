import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { motion } from "framer-motion";
import {usePlayer} from "../context/PlayerContext";

function CreatePost({ addPost, closeModal, groupId }) {
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [previewVideos, setPreviewVideos] = useState([]);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);
  const [userTracks, setUserTracks] = useState([]);
  const [isLoadingTracks, setIsLoadingTracks] = useState(false);

  useEffect(() => {
    const fetchUserTracks = async () => {
      const token = localStorage.getItem('token');
      setIsLoadingTracks(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tracks`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUserTracks(response.data);
      } catch (error) {
        console.error('Ошибка загрузки треков', error);
      } finally {
        setIsLoadingTracks(false);
      }
    };

    if (isTrackModalOpen) fetchUserTracks();
  }, [isTrackModalOpen]);

  const handleContentChange = (e) => {
    setContent(e.target.value);
    setErrorMessage('');
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    const videoFiles = files.filter(file => file.type.startsWith('video/'));

    setImages(imageFiles);
    setVideos(videoFiles.length > 0 ? [videoFiles[0]] : []);

    const imagePreviewUrls = imageFiles.map(file => URL.createObjectURL(file));
    const videoPreviewUrls = videoFiles.slice(0, 1).map(file => URL.createObjectURL(file));

    setPreviewImages(imagePreviewUrls);
    setPreviewVideos(videoPreviewUrls);
    setErrorMessage('');
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
    setPreviewImages(previewImages.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();

    if (!content && images.length === 0 && videos.length === 0 && !selectedTrack) {
      setErrorMessage('Добавьте текст, изображение, видео или трек');
      return;
    }

    if (selectedTrack) formData.append('track_id', selectedTrack.id);
    if (content) formData.append('content', content);
    if (groupId) formData.append('group_id', groupId);

    images.forEach(image => formData.append('images[]', image));
    if (videos.length > 0) formData.append('video', videos[0]);

    setIsLoadingPost(true);
    setIsUploadingFiles(true);
    setErrorMessage('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      addPost(response.data);
      resetForm();
      closeModal();
    } catch (error) {
      console.error('Ошибка при создании поста!', error);
      setErrorMessage(error.response?.data?.message || 'Произошла ошибка при создании поста');
    } finally {
      setIsLoadingPost(false);
      setIsUploadingFiles(false);
    }
  };

  const resetForm = () => {
    setContent('');
    setImages([]);
    setVideos([]);
    setPreviewImages([]);
    setPreviewVideos([]);
    setSelectedTrack(null);
    setUploadProgress(0);
  };

  const TrackModal = () => {
    const [activeTab, setActiveTab] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [processingFavorite, setProcessingFavorite] = useState(null);
    const { tracks: contextTracks, setTracks } = usePlayer();

    // Объединение треков из контекста и локального состояния
    const tracks = userTracks.map(ut =>
        contextTracks.find(ct => ct.id === ut.id) || ut
    );

    const filteredTracks = tracks.filter(track => {
      const matchesSearch = track.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          track.artist.toLowerCase().includes(searchQuery.toLowerCase());
      return activeTab === 'favorites'
          ? track.is_favorite && matchesSearch
          : matchesSearch;
    });

    const handleToggleFavorite = async (trackId) => {
      setProcessingFavorite(trackId);
      try {
        const track = tracks.find(t => t.id === trackId);
        const method = track.is_favorite ? 'DELETE' : 'POST';

        const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks/${trackId}/favorite`, {
          method,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) throw new Error('Ошибка при обновлении избранного');

        const updatedTracks = tracks.map(t =>
            t.id === trackId ? { ...t, is_favorite: !t.is_favorite } : t
        );

        setUserTracks(updatedTracks);
        setTracks(updatedTracks);
      } catch (err) {
        console.error(err);
      } finally {
        setProcessingFavorite(null);
      }
    };

    return (
        <div className="fixed inset-0 bg-black/60 dark:bg-neutral-900/80 flex items-center justify-center p-4 z-50 backdrop-blur-sm">
          <div className="bg-white dark:bg-neutral-800 rounded-2xl p-4 w-full max-w-lg max-h-[90vh] overflow-y-auto shadow-xl">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-neutral-800 dark:text-white">Выберите трек</h2>
              <button
                  onClick={() => setIsTrackModalOpen(false)}
                  className="bg-gray-200 dark:bg-neutral-600 w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200"
              >
                <i className="fas fa-times text-gray-700 dark:text-gray-300"></i>
              </button>
            </div>

            <div className="mb-6">
              <div className="relative">
                <input
                    type="text"
                    placeholder="Поиск по трекам..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full pl-4 pr-10 py-3 rounded-xl bg-gray-100 dark:bg-neutral-700 border-0 focus:ring-2 focus:ring-purple-500 text-neutral-800 dark:text-white placeholder-gray-500 dark:placeholder-neutral-400"
                />
                <i className="fas fa-search absolute right-4 top-3.5 text-gray-500 dark:text-neutral-400"/>
              </div>
            </div>

            <div className="flex mb-6 gap-1 relative bg-gray-100 dark:bg-neutral-700 p-1 rounded-xl">
              <button
                  onClick={() => setActiveTab('favorites')}
                  className={`flex-1 py-2.5 px-4 rounded-lg transition-colors ${
                      activeTab === 'favorites'
                          ? 'bg-white dark:bg-neutral-600 shadow-sm text-purple-500'
                          : 'text-neutral-600 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-600/50'
                  }`}
              >
                Избранные
              </button>
              <button
                  onClick={() => setActiveTab('all')}
                  className={`flex-1 py-2.5 px-4 rounded-lg transition-colors ${
                      activeTab === 'all'
                          ? 'bg-white dark:bg-neutral-600 shadow-sm text-purple-500'
                          : 'text-neutral-600 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-600/50'
                  }`}
              >
                Все треки
              </button>
            </div>

            {isLoadingTracks ? (
                <div className="flex flex-col items-center justify-center py-10">
                  <ClipLoader
                      color="#7c3aed"
                      size={40}
                      cssOverride={{
                        borderWidth: '4px',
                        borderLeftColor: 'transparent'
                      }}
                  />
                  <p className="mt-4 text-gray-500 dark:text-neutral-400">Загрузка треков...</p>
                </div>
            ) : (
                <div className="space-y-2">
                  {filteredTracks.map(track => (
                      <motion.div
                          key={track.id}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="group"
                      >
                        <div
                            className="p-3 flex items-center gap-4 rounded-xl cursor-pointer transition-all
                    bg-white dark:bg-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-600
                    border-2 border-transparent hover:border-purple-500/20"
                            onClick={() => {
                              setSelectedTrack(track);
                              setIsTrackModalOpen(false);
                            }}
                        >
                          <img
                              src={track.album_art
                                  ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}`
                                  : '/default-cover.jpg'}
                              className="w-14 h-14 rounded-xl object-cover shadow-sm"
                              alt="Обложка"
                          />
                          <div className="flex-1 min-w-0">
                            <p className="font-semibold truncate text-neutral-800 dark:text-white">
                              {track.title}
                            </p>
                            <p className="text-sm truncate text-gray-500 dark:text-neutral-300">
                              {track.artist}
                            </p>
                          </div>
                          <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleToggleFavorite(track.id);
                              }}
                              disabled={processingFavorite === track.id}
                              className={`p-2 rounded-full transition-colors ${
                                  track.is_favorite
                                      ? 'text-red-500 hover:bg-red-500/10'
                                      : 'text-gray-400 dark:text-neutral-400 hover:bg-gray-100 dark:hover:bg-neutral-500/20'
                              }`}
                          >
                            {processingFavorite === track.id ? (
                                <ClipLoader size={18} color="#ef4444"/>
                            ) : (
                                <i className={`fa-heart ${track.is_favorite ? 'fas' : 'far'} text-xl`}/>
                            )}
                          </button>
                        </div>
                      </motion.div>
                  ))}
                </div>
            )}

            {filteredTracks.length === 0 && !isLoadingTracks && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="flex flex-col items-center justify-center py-8"
                >
                  <div className="relative mb-4">
                    <i className="fas fa-music text-4xl text-purple-500/30"/>
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/50 dark:via-neutral-800/50 to-transparent"/>
                  </div>
                  <p className="text-gray-500 dark:text-neutral-400 text-center">
                    {activeTab === 'favorites'
                        ? "У вас пока нет избранных треков"
                        : searchQuery
                            ? "Ничего не найдено"
                            : "Нет доступных треков"}
                  </p>
                </motion.div>
            )}
          </div>
        </div>
    );
  };


  return (
      <form onSubmit={handleSubmit} className="space-y-6">
      <textarea
          value={content}
          onChange={handleContentChange}
          className="w-full rounded-lg p-3 bg-gray-100 text-gray-800 dark:bg-neutral-700 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-violet-500 transition-shadow duration-300"
          placeholder="Напишите что-нибудь (необязательно)"
          rows="4"
      />

        <input
            type="file"
            multiple
            accept="image/*, video/*"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-700 dark:text-white file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 dark:file:bg-neutral-700 dark:file:text-white transition duration-300"
        />

        <div className="max-h-48 overflow-y-auto mt-3 space-y-2">
          {previewImages.length > 0 && (
              <div className="flex gap-4 mt-4 overflow-x-auto">
                {previewImages.map((url, index) => (
                    <div key={index} className="relative group">
                      <img
                          src={url}
                          alt={`Preview ${index}`}
                          className="max-w-xs max-h-32 rounded-lg shadow-lg border border-gray-200 dark:border-neutral-700 transition-transform duration-300 ease-in-out transform group-hover:scale-95"
                      />
                      <button
                          onClick={() => handleRemoveImage(index)}
                          className="absolute top-1 right-1 bg-gray-800 bg-opacity-60 text-white px-2 py-0.5 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                          title="Удалить"
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                ))}
              </div>
          )}

          {previewVideos.length > 0 && (
              <div className="grid grid-cols-1 gap-3 mt-4">
                {previewVideos.map((url, index) => (
                    <div key={index} className="relative">
                      <video controls src={url} className="w-full h-32 object-cover rounded-lg shadow-md" />
                    </div>
                ))}
              </div>
          )}
        </div>

        <div className="flex">
          <button
              type="button"
              onClick={() => setIsTrackModalOpen(true)}
              className="flex items-center gap-2 px-4 py-2 text-neutral-700 dark:text-white hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-full transition-colors duration-300"
          >
            <i className="fas fa-music"></i>
            Музыка
          </button>
        </div>

        {selectedTrack && (
            <div className="flex items-center justify-between p-3 bg-gray-100 dark:bg-neutral-700 rounded-xl">
              <div className="flex items-center gap-3 flex-1 min-w-0">
                <img
                    src={selectedTrack.album_art
                        ? `${process.env.REACT_APP_BASE_URL}/storage/${selectedTrack.album_art}`
                        : '/default-cover.jpg'}
                    className="w-12 h-12 rounded object-cover"
                    alt="Обложка"
                />
                <div className="flex-1 min-w-0">
                  <p className="font-medium truncate text-neutral-800 dark:text-white">{selectedTrack.title}</p>
                  <p className="text-sm truncate text-gray-500 dark:text-neutral-300">{selectedTrack.artist}</p>
                </div>
              </div>
              <button
                  onClick={() => setSelectedTrack(null)}
                  className="text-neutral-400 hover:text-neutral-500 transition-colors duration-300"
              >
                <i className="fas fa-times text-lg"></i>
              </button>
            </div>
        )}

        {isTrackModalOpen && <TrackModal />}

        {isUploadingFiles && (
            <div className="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700 mt-3 shadow-lg overflow-hidden">
              <div
                  className="bg-gradient-to-r from-purple-500 to-purple-700 h-full rounded-full transition-all duration-300 ease-in-out"
                  style={{ width: `${uploadProgress}%` }}
              />
              <p className="text-sm text-gray-600 dark:text-gray-300 mt-1 text-center">
                Загрузка: {uploadProgress}%
              </p>
            </div>
        )}

        {errorMessage && (
            <div className="text-red-500 text-center mt-2 px-4 py-2 bg-red-50 dark:bg-red-900/20 rounded-lg">
              {errorMessage}
            </div>
        )}

        {isLoadingPost ? (
            <button
                type="button"
                disabled
                className="w-full bg-gray-400 text-white py-3 rounded-full flex justify-center"
            >
              <ClipLoader color="white" size={20} />
            </button>
        ) : (
            <button
                type="submit"
                className="w-full bg-violet-500 text-white py-3 rounded-full flex justify-center items-center transition-colors duration-300 ease-in-out hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500"
            >
              <i className="fas fa-paper-plane mr-2"></i> Опубликовать
            </button>
        )}
      </form>
  );
}

export default CreatePost;