import React from "react";
import BackLink from "../BackLink";

export default function Support() {
    return (
        <div className="container mx-auto p-4 grid pt-16">
            <BackLink />
            <h1 className="text-3xl font-extrabold mb-4 dark:text-white">Поддержка</h1>
            <h2 className="text-xl font-bold text-gray-800 mb-4 dark:text-white">Скоро здесь все появится</h2>
        </div>
    );
}

