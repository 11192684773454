import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ClipLoader } from "react-spinners";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay, EffectCoverflow} from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { ArrowUpFromLine } from "lucide-react";
import useWindowSize from "../hooks/useWindowSize";

const AlbumList = () => {
    const [albums, setAlbums] = useState([]);
    const [loading, setLoading] = useState(true);
    const swiperRef = useRef(null);
    const { width } = useWindowSize();
    const isMobile = width < 768;


    useEffect(() => {
        const fetchAlbums = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/albums`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                const data = await response.json();
                setAlbums(data);
            } catch (err) {
                console.error("Ошибка загрузки альбомов:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchAlbums();
    }, []);

    const handleNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
            className="pb-20 h-screen "
        >
            <div className="flex justify-between items-center mb-8 ">
                <h2 className="text-3xl font-extrabold text-neutral-800 dark:text-white">Альбомы</h2>
                <Link
                    to="/upload-album"
                    className="flex items-center gap-2 px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800/50 rounded-full transition-colors duration-150 group/button"
                >
                    <div className="relative h-8 w-8 flex items-center justify-center">
                        <div
                            className="absolute inset-0 bg-purple-500/10 rounded-full scale-50 group-hover/button:scale-100 transition-transform"/>
                        <ArrowUpFromLine className='text-purple-500 group-hover/button:text-purple-600 transition-colors duration-150' />
                    </div>
                    <span className="hidden md:inline text-lg font-medium text-neutral-700 dark:text-neutral-300">
                        Добавить альбом
                    </span>
                </Link>
            </div>

            {loading ? (
                <div className="flex justify-center items-center h-40">
                    <ClipLoader
                        color="#8b5cf6"
                        loading={loading}
                        size={50}
                        cssOverride={{
                            borderWidth: '3px',
                            borderLeftColor: 'transparent'
                        }}
                    />
                </div>
            ) : albums.length === 0 ? (
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                    className="text-center py-10"
                >
                    <i className="fas fa-music text-4xl text-gray-400 mb-4"/>
                    <p className="text-gray-500 text-lg">Альбомов пока нет</p>
                </motion.div>
            ) : (
                <div className="relative">
                    {!isMobile && (
                        <>
                            <button
                                onClick={handlePrev}
                                className="absolute left-0 top-[40%] z-10 transform -translate-y-1/2 dark:text-white/80 bg-white/50 dark:bg-neutral-800/50 p-1 rounded-full shadow-lg hover:bg-purple-500 hover:text-white transition-colors duration-200"
                                style={{ backdropFilter: "blur(10px)" }}
                            >
                                <FaChevronLeft className="text-xl"/>
                            </button>
                            <button
                                onClick={handleNext}
                                className="absolute right-0 top-[40%] z-10 transform -translate-y-1/2 dark:text-white/80 bg-white/50 dark:bg-neutral-800/50 p-1 rounded-full shadow-lg hover:bg-purple-500 hover:text-white transition-colors duration-200"
                                style={{ backdropFilter: "blur(10px)" }}
                            >
                                <FaChevronRight className="text-xl"/>
                            </button>
                        </>
                    )}

                    <Swiper
                        key={isMobile ? 'mobile' : 'desktop'}
                        ref={swiperRef}
                        modules={isMobile ? [Autoplay, EffectCoverflow] : [Autoplay]}
                        effect={isMobile ? "coverflow" : "slide"}
                        centeredSlides={isMobile}
                        coverflowEffect={
                            isMobile ? {
                                rotate: 20,
                                stretch: 0,
                                depth: 50,
                                modifier: 1.5,
                                slideShadows: false,
                            } : undefined
                        }
                        spaceBetween={isMobile ? 10 : 20}
                        slidesPerView={isMobile ? "auto" : 1.5}
                        autoplay={{ delay: 3000 }}
                        breakpoints={
                            !isMobile ? {
                                480: { slidesPerView: 2 },
                                640: { slidesPerView: 2 },
                                768: { slidesPerView: 3 },
                                1024: { slidesPerView: 4 },
                                1280: { slidesPerView: 5 },
                            } : undefined
                        }
                        className="!w-full"
                    >
                        {albums.map((album) => (
                            <SwiperSlide
                                key={album.id}
                                className={isMobile ? "!w-[70%]" : ""}
                            >
                                <motion.div
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{duration: 0.5}}
                                    className="block group transform transition-transform duration-200 hover:scale-105"
                                >
                                    <Link to={`/albums/${album.id}`}>
                                        <div className="relative w-full aspect-square bg-gray-200 dark:bg-neutral-800 rounded-lg overflow-hidden shadow-lg">
                                            <img
                                                src={album.album_art ? `${process.env.REACT_APP_BASE_URL}/storage/${album.album_art}` : 'placeholder.png'}
                                                alt={album.title}
                                                className="w-full h-full object-cover"
                                            />
                                            <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-200"/>
                                        </div>
                                        <div className="mt-3 text-center">
                                            <p className="font-semibold text-neutral-800 dark:text-white truncate">{album.title}</p>
                                            <p className="text-sm text-neutral-500 dark:text-neutral-400">{album.artist}</p>
                                        </div>
                                    </Link>
                                </motion.div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </motion.div>
    );
};

export default AlbumList;