import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import AvatarEditor from 'react-avatar-editor';
import { motion } from 'framer-motion';
import { X, Trash2, Upload, ZoomIn } from 'lucide-react';

const UpdateAvatar = ({ closeModal }) => {
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState(1);
  const { user, setUser } = useContext(AuthContext);
  const editorRef = useRef();

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      toast.error('Неподдерживаемый формат! Используйте JPG, PNG или GIF.');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error('Размер файла превышает 5MB!');
      return;
    }

    setAvatar(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleUpload = async (formData) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/update-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      setUser(response.data.user);
      toast.success('Аватар успешно обновлён!');
      closeModal();
    } catch (error) {
      console.error('Ошибка обновления аватара:', error);
      toast.error('Не удалось обновить аватар.');
    } finally {
      setIsLoading(false);
      toast.dismiss();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!avatar) {
      toast.error('Выберите изображение.');
      return;
    }

    setIsLoading(true);
    toast.loading('Загрузка...');

    if (editorRef.current) {
      editorRef.current.getImageScaledToCanvas().toBlob(
          (blob) => {
            const formData = new FormData();
            formData.append('avatar', blob);
            handleUpload(formData);
          },
          'image/jpeg',
          0.92
      );
    }
  };

  const handleDeleteAvatar = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-avatar`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setUser((prevUser) => ({ ...prevUser, avatar: null }));
      toast.success('Аватар успешно удалён!');
      closeModal();
    } catch (error) {
      console.error('Ошибка удаления аватара:', error);
      toast.error('Не удалось удалить аватар.');
    }
  };

  console.log('User data:', user);

  return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
        <motion.div
            initial={{opacity: 0, scale: 0.95}}
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0, scale: 0.95}}
            className="relative bg-white dark:bg-neutral-900 rounded-xl shadow-2xl max-w-2xl w-full mx-4 flex flex-col lg:flex-row overflow-hidden"
        >
          <button
              onClick={closeModal}
              className="absolute top-4 right-4 p-2 bg-gray-200 dark:bg-neutral-600 w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200"
          >
            <i className="fas fa-times text-gray-700 dark:text-gray-300"></i>
          </button>

          {/* Левая секция - текущий аватар */}
          <div className="lg:w-1/3 p-6 border-r border-neutral-100 dark:border-neutral-800">
            <h2 className="text-xl font-semibold mb-4 dark:text-white">Текущий аватар</h2>
            <div className="relative group">
              {!user ? (
                  <div className="w-32 h-32 rounded-full bg-gray-200 animate-pulse mx-auto"></div>
              ) : (
                  <>
                    <img
                        src={
                          user.avatar
                              ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}`
                              : `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name || 'User')}&background=random`
                        }
                        alt="Current Avatar"
                        className="w-32 h-32 rounded-full object-cover mx-auto border-2 border-gray-100 dark:border-neutral-800"
                        onError={(e) => {
                          e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name || 'User')}&background=random`;
                        }}
                    />
                    {user.avatar && (
                        <button
                            onClick={handleDeleteAvatar}
                            className="absolute bottom-2 right-2 p-1.5 bg-white dark:bg-neutral-800 rounded-full shadow-sm hover:bg-red-50 dark:hover:bg-red-900/20 text-red-500"
                        >
                          <Trash2 className="w-4 h-4"/>
                        </button>
                    )}
                  </>
              )}
            </div>
          </div>

          {/* Правая секция - редактирование */}
          <div className="lg:w-2/3 p-6">
            <h3 className="text-lg font-medium mb-6 dark:text-white">Редактирование</h3>

            <form onSubmit={handleSubmit} className="space-y-4">
              <label
                  className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed border-neutral-300 dark:border-neutral-700 rounded-xl cursor-pointer hover:border-neutral-400 transition-colors">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                />
                <Upload className="w-6 h-6 text-neutral-400 mb-2"/>
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                Выберите файл
              </span>
              </label>

              {preview && (
                  <div className="relative">
                    <AvatarEditor
                        ref={editorRef}
                        image={preview}
                        width={200}
                        height={200}
                        border={20}
                        color={[255, 255, 255, 0.8]}
                        scale={scale}
                        className="mx-auto rounded-lg shadow-lg"
                    />
                    <div className="mt-4">
                      <div className="flex items-center gap-2 text-sm text-neutral-600 dark:text-neutral-400">
                        <ZoomIn className="w-4 h-4"/>
                        <input
                            type="range"
                            min="1"
                            max="2"
                            step="0.01"
                            value={scale}
                            onChange={(e) => setScale(parseFloat(e.target.value))}
                            className="w-full bg-transparent"
                        />
                      </div>
                    </div>
                  </div>
              )}

              <div className="flex gap-3 mt-6">
                <motion.button
                    whileTap={{scale: 0.98}}
                    type="submit"
                    disabled={isLoading}
                    className="flex-1 bg-violet-500 hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 text-white px-6 py-2 rounded-lg transition-colors"
                >
                  {isLoading ? 'Сохранение...' : 'Сохранить'}
                </motion.button>
              </div>
            </form>
          </div>

          {/* Мобильная версия */}
          <div className="lg:hidden p-6 border-t border-neutral-100 dark:border-neutral-800">
            <button
                onClick={handleDeleteAvatar}
                className="w-full flex items-center justify-center gap-2 text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20 py-2 rounded-lg transition-colors"
            >
              <Trash2 className="w-4 h-4"/>
              Удалить аватар
            </button>
          </div>
        </motion.div>
      </div>
  );
};

export default UpdateAvatar;
