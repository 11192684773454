import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import useWindowSize from "../hooks/useWindowSize";
import { motion, AnimatePresence } from 'framer-motion';
import ConfirmLogout from "./ConfirmLogout";
import {
  UserCircle,
  Shield,
  SlidersHorizontal,
  Palette,
  LogOut,
  ChevronDown, Info
} from "lucide-react";

function Header() {
  const { isLoggedIn, user, logout } = useContext(AuthContext);
  const [userData, setUserData] = useState(user);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoggedIn && !userData) {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setUserData(response.data);
        } catch (error) {
          console.error('Ошибка при загрузке данных пользователя!', error);
        }
      }
    };

    fetchUserData();
  }, [isLoggedIn, userData]);

  const handleLogout = () => {
    logout();
    setUserData(null);
    setDropdownVisible(false);
    navigate('/login');
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const changeTheme = () => {
    document.documentElement.classList.toggle('dark');
    const currentTheme = document.documentElement.classList.contains('dark') ? 'dark' : 'light';
    localStorage.setItem('theme', currentTheme);
    setDropdownVisible(false);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownVisible]);

  const handleLogoutClick = () => {
    setDropdownVisible(false);
    setShowConfirmLogout(true);
  };

  const closeConfirmLogout = () => {
    setShowConfirmLogout(false);
  };

  const confirmLogout = () => {
    setShowConfirmLogout(false);
    handleLogout();
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".beta-container")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const defaultAvatar = `https://ui-avatars.com/api/?name=${userData?.name}&background=random`;

  return (
      <header
          className="fixed top-0 left-0 w-full z-50 bg-white dark:bg-neutral-800 shadow-sm border-b border-gray-200 dark:border-neutral-800">
        <div className="container mx-auto flex items-center justify-between py-2 px-4 md:px-6">
          <div className="flex items-center">
            <Link to="/" className="text-xl font-semibold text-gray-800 dark:text-white">
              <div className="flex items-center">
                <img src={`/logo.png`} alt="Chattify" className="h-6 w-auto mr-2"/>
                chattify
              </div>
            </Link>
            <div className="relative ml-2 beta-container">
      <span
          className="text-pink-600 font-semibold text-sm flex items-center space-x-4 rounded-full px-2 bg-pink-100 dark:bg-pink-200 cursor-pointer hover:bg-pink-200 dark:hover:bg-pink-300 transition-all duration-300"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
      >
        Beta
        <Info className="w-3 h-3 ml-1"/>
      </span>
              <div
                  className={`absolute left-1/2 transform -translate-x-1/2 mt-2 w-max bg-neutral-100 dark:bg-neutral-700 dark:text-white text-xs rounded-lg py-2 px-3 shadow-lg transition-opacity duration-300 ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
                  style={{zIndex: 1000}}
              >
                <p>Приложение в стадии активной разработки!</p>
                <p>Могут возникать непредвиденные ошибки</p>
              </div>
            </div>
          </div>
          <nav className="flex items-center space-x-4 md:space-x-6">
            {!isLoggedIn && (
                <>
                  <motion.div whileHover={{scale: 1.05}} whileTap={{scale: 0.95}}>
                    <Link to="/login"
                          className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition text-sm md:text-base">
                      Войти
                    </Link>
                  </motion.div>
                  <motion.div whileHover={{scale: 1.05}} whileTap={{scale: 0.95}}>
                    <Link to="/register"
                          className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition text-sm md:text-base">
                      Регистрация
                    </Link>
                  </motion.div>
                </>
            )}
            {isLoggedIn && (
                <div className="relative" ref={dropdownRef}>
                  {userData && (
                      <div
                          className="flex items-center space-x-1 cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700 px-1 rounded-full"
                          onClick={toggleDropdown}>
                        <img
                            src={userData.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${userData.avatar}` : defaultAvatar}
                            alt="Avatar"
                            className="w-9 h-9 rounded-full border-2 border-white dark:border-neutral-800 shadow-sm"
                        />

                        <motion.div
                            animate={{rotate: dropdownVisible ? 180 : 0}}
                            transition={{duration: 0.2}}
                        >
                          <ChevronDown className="w-5 h-5 text-gray-500 transition-transform dark:text-gray-400"/>

                        </motion.div>

                      </div>
                  )}
                  <AnimatePresence>
                    {dropdownVisible && (
                        <motion.div
                            className="absolute right-0 mt-3 z-50 w-60 origin-top-right bg-white dark:bg-neutral-900 border border-gray-200 dark:border-neutral-800 rounded-2xl shadow-2xl overflow-hidden"
                            initial={{opacity: 0, scale: 0.95}}
                            animate={{opacity: 1, scale: 1}}
                            exit={{opacity: 0, scale: 0.95}}
                        >
                          <div className="p-2 space-y-1">
                            <div className="px-4 py-3 border-b border-gray-200 dark:border-neutral-800">
                              <p className="text-sm font-medium text-neutral-900 dark:text-white">{userData.name}</p>
                              <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">{userData.email}</p>
                            </div>

                            <Link
                                to="/profile"
                                className="flex items-center gap-3 px-4 py-2.5 text-sm rounded-lg text-neutral-700 dark:text-neutral-200 hover:bg-gray-100 dark:hover:bg-neutral-800"
                                onClick={closeDropdown}
                            >
                              <UserCircle className="w-5 h-5" strokeWidth="2"/>
                              Профиль
                            </Link>

                            {userData?.role === 'admin' && (
                                <Link
                                    to="/admin"
                                    className="flex items-center gap-3 px-4 py-2.5 text-sm rounded-lg text-neutral-700 dark:text-neutral-200 hover:bg-gray-100 dark:hover:bg-neutral-800"
                                    onClick={closeDropdown}
                                >
                                  <Shield className="w-5 h-5" strokeWidth="2"/>
                                  Админ-панель
                                </Link>
                            )}

                            {width < 768 && (
                                <Link
                                    to="/settings"
                                    className="flex items-center gap-3 px-4 py-2.5 text-sm rounded-lg text-neutral-700 dark:text-neutral-200 hover:bg-gray-100 dark:hover:bg-neutral-800"
                                    onClick={closeDropdown}
                                >
                                  <SlidersHorizontal className="w-5 h-5" strokeWidth="2"/>
                                  Настройки
                                </Link>
                            )}

                            <button
                                onClick={changeTheme}
                                className="flex items-center gap-3 w-full px-4 py-2.5 text-sm rounded-lg text-neutral-700 dark:text-neutral-200 hover:bg-gray-100 dark:hover:bg-neutral-800"
                            >
                              <Palette className="w-5 h-5" strokeWidth="2"/>
                              Тема оформления
                            </button>

                            <div className="border-t border-gray-200 dark:border-neutral-800 mx-4"/>

                            <button
                                onClick={handleLogoutClick}
                                className="flex items-center gap-3 w-full px-4 py-2.5 text-sm rounded-lg text-red-500 hover:bg-red-100 dark:hover:bg-red-900/20"
                            >
                              <LogOut className="w-5 h-5" strokeWidth="2"/>
                              Выйти из аккаунта
                            </button>
                          </div>
                        </motion.div>
                    )}
                  </AnimatePresence>
                </div>
            )}
          </nav>
        </div>
        <ConfirmLogout
            isOpen={showConfirmLogout}
            onClose={closeConfirmLogout}
            onConfirm={confirmLogout}
        />
      </header>
  );
}

export default Header;