import React, { useState, useEffect, useContext } from 'react';
import Aside from './Aside';
import PostList from './PostList';
import PostModal from './PostModal';
import CreatePost from './CreatePost';
import useWindowSize from '../hooks/useWindowSize';
import BottomNav from './BottomNav';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { motion } from 'framer-motion';
import RightSidebar from './RightSidebar';
import {Link} from "react-router-dom";

function Home() {
    const { isLoggedIn, user } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [posts, setPosts] = useState([]);
    const [showCreateButton, setShowCreateButton] = useState(true);
    const { width } = useWindowSize();
    const [userData, setUserData] = useState(user);

    useEffect(() => {
        const fetchUserData = async () => {
            if (isLoggedIn && !userData) {
                const token = localStorage.getItem('token');
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUserData(response.data);
                } catch (error) {
                    console.error('Error fetching user data', error);
                }
            }
        };
        fetchUserData();
    }, [isLoggedIn, userData]);

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    useEffect(() => {
        let lastScrollY = window.scrollY;
        const handleScroll = () => {
            setShowCreateButton(window.scrollY <= lastScrollY);
            lastScrollY = window.scrollY;
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const addPost = (newPost) => setPosts([newPost, ...posts]);

    const renderFloatingButton = () => (
        <motion.button
            onClick={openModal}
            className={`fixed bottom-16 right-6 flex items-center justify-center bg-violet-500 hover:bg-violet-600 text-white font-semibold rounded-full shadow-lg transition-all duration-300 z-30
                ${width < 768 ? 'w-10 h-10' : 'px-3 py-3 w-12 h-12'}`}
            initial={{ scale: 0 }}
            animate={{ scale: showCreateButton ? 1 : 0 }}
            transition={{ duration: 0.3 }}
        >
            <i className="fas fa-plus text-xl"></i>
        </motion.button>
    );

    return (
        <div className="container mx-auto flex flex-col lg:flex-row gap-4 p-2 pt-16">
            {width >= 1024 ? <Aside className="w-1/4" /> : <BottomNav />}

            <main className="w-full lg:w-3/4 dark:bg-neutral-900 mt-4">
                <header className="mb-6">
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        className="flex items-center justify-between mb-4"
                    >
                        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Главная</h2>
                        <div className="flex items-center">
                            <button
                                onClick={openModal}
                                className="transition-transform duration-200 transform hover:scale-95"
                            >
                                <div
                                    className="flex items-center space-x-2 border border-violet-500 rounded-full px-2 py-0.5 hover:bg-violet-50 dark:hover:bg-neutral-800">
                                    <i className="fas fa-pen text-violet-500"></i>
                                    <span className="text-violet-500 dark:text-white font-semibold">
                                        {width < 1024 ? 'Пост' : 'Создать пост'}
                                    </span>
                                </div>
                            </button>
                        </div>

                    </motion.div>
                </header>

                <PostList posts={posts} setPosts={setPosts}/>

                {renderFloatingButton()}
            </main>

            {width > 1024 && <RightSidebar/>}
            {isModalOpen && (
                <PostModal closeModal={closeModal}>
                    <CreatePost addPost={addPost} closeModal={closeModal}/>
                </PostModal>
            )}
            {userData?.id === 2 && Date.now() >= new Date('2025-02-14').getTime() && (
                <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 z-30">
                    <Link
                        to="/люблю_тебя_полиночка"
                        className="
                inline-flex items-center justify-center
                bg-white
                hover:bg-pink-50
                text-pink-600 hover:text-pink-700
                font-semibold text-sm
                py-3 px-6
                rounded-full
                shadow-md
                hover:shadow-lg
                transform hover:scale-105
                transition-all duration-300
                border-2 border-pink-200
                animate-float
            "
                    >
                        Для Полиночки
                        <span className="ml-2 animate-heartbeat">❤️</span>
                    </Link>
                </div>
            )}

        </div>
    );
}

export default Home;
