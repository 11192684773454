import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

function FriendListForProfile() {
  const [friends, setFriends] = useState([]);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchFriends = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFriends(response.data);
    } catch (error) {
      console.error('Error fetching friends:', error);
    }
  };

  const handleViewProfile = (friendId) => {
    navigate(`/profile/${friendId}`);
  };

  useEffect(() => {
    if (isLoggedIn) fetchFriends();
  }, [isLoggedIn]);

  return (
      <div className="mx-auto w-full max-w-3xl px-4 py-2">
        <div className="mb-3">
          <Link
              to="/friends"
              className="text-neutral-700 dark:text-neutral-300 hover:text-neutral-900 dark:hover:text-neutral-100 transition-colors"
          >
            <h2 className="text-lg font-medium">
              Друзья <span className="text-neutral-400 dark:text-neutral-600">({friends.length})</span>
            </h2>
          </Link>
        </div>

        <ul className="flex gap-3 overflow-x-auto pb-2 scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-neutral-800">
          {friends.length === 0 ? (
              <p className="text-gray-400 dark:text-neutral-600 text-sm py-1">
                Список друзей пуст
              </p>
          ) : (
              friends.map(friend => (
                  <li
                      key={friend.id}
                      className="flex-shrink-0 cursor-pointer"
                      onClick={() => handleViewProfile(friend.friend.id)}
                  >
                    <div className="relative items-center justify-center ">
                      <img
                          src={friend.friend.avatar
                              ? `${process.env.REACT_APP_BASE_URL}/storage/${friend.friend.avatar}`
                              : `https://ui-avatars.com/api/?name=${friend.friend.name}&background=random`
                          }
                          alt={friend.friend.name}
                          className="w-12 h-12 rounded-full object-cover border border-gray-200 dark:border-neutral-800"
                      />
                      {friend.is_online && (
                          <div className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-400 rounded-full border border-white dark:border-neutral-900"></div>
                      )}
                    </div>
                    <p className="text-xs text-neutral-600 dark:text-neutral-400 mt-1 text-center max-w-[90px] truncate">
                      {friend.friend.name || 'Без имени'}
                    </p>
                  </li>
              ))
          )}
        </ul>
      </div>
  );
}

export default FriendListForProfile;